import gsap from 'gsap';
import { Draggable, InertiaPlugin, MotionPathPlugin } from 'gsap/all';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

gsap.registerPlugin(Draggable, InertiaPlugin, MotionPathPlugin);

class MediaFilterWheel {
	constructor() {
		// Subscribe to content additions
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.init();
		});
	}

	init() {
		document
			.querySelectorAll('.media-filter:not([data-init])')
			.forEach((wheel) => this.setupWheel(wheel));
	}

	setupWheel(element: Element) {
		// Skip the hidden template version of this
		if (element.closest('[data-ignore]')) return;
		const mediaFilterEl = element as HTMLElement;
		mediaFilterEl.dataset.init = 'true';

		const wheel = mediaFilterEl.querySelector('.wheel');
		// each of the boxes in the wheel
		const boxes = Array.from(
			mediaFilterEl.querySelectorAll('.wheel__box'),
		) as HTMLElement[];
		// the svg path that the boxes will follow
		const path = mediaFilterEl.querySelector('.wheel-path');

		// We need these
		if (!wheel || !path || !boxes.length) return;

		// Set the motion path for each box
		gsap.set(boxes, {
			motionPath: {
				path: path as SVGPathElement,
				align: path,
				alignOrigin: [0.5, 0.5],
				start: -0.25,
				end: (i: number) => i / boxes.length - 0.25,
				autoRotate: 0,
			},
		});

		// Make the wheel draggable
		Draggable.create(wheel, {
			type: 'rotation',
			inertia: true,
			// Fix click events on mobile
			onClick: (event) => {
				const link =
					event.target?.nodeName === 'a'
						? event.target
						: event.target.closest('a');
				if (link) {
					link.click();
				}
			},
			snap: (endVal) => {
				// Calculate the snap value
				const snapVal = gsap.utils.snap(360 / boxes.length, endVal);
				// Calculate the box index
				let boxIndex = Math.round(snapVal / (360 / boxes.length));
				// Ensure the box index is within the range of the boxes array
				boxIndex %= boxes.length;
				// If the box index is negative, invert it
				if (boxIndex < 0) boxIndex *= -1;
				// Toggle the active classes
				const snappedBox = boxes[boxIndex];
				this.updateActiveBox(boxes, snappedBox);
				// Return the snap value so the wheel snaps to the correct position
				return snapVal;
			},
		});

		boxes.forEach((box: HTMLElement) => {
			const el = box as HTMLElement;

			el.querySelectorAll('a').forEach((link) => {
				const parent = link.parentElement as HTMLElement;
				link.addEventListener('focus', (e) => {
					e.preventDefault();
					// Keeps the focus on the wheel when using the keyboard
					mediaFilterEl.scrollTo(0, 0);

					// Get the index of the focused box
					const currentIndex = Array.from(boxes).indexOf(parent);
					// Calculate the degree of rotation for the wheel
					const rotation = -(currentIndex * (360 / boxes.length));
					// and rotate the wheel to that position
					gsap.to(wheel, { rotation });
					this.updateActiveBox(boxes, el);
				});
			});
		});
	}

	private updateActiveBox(boxes: HTMLElement[], activeBox: HTMLElement) {
		// Remove all active classes from the boxes
		boxes.forEach((box: HTMLElement) => {
			box.classList.remove('wheel__box--active');
		});
		// Make current box active
		activeBox.classList.add('wheel__box--active');
	}
}

export default MediaFilterWheel;
