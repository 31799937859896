import {
	FETCH_EVENTS,
	MAIN,
	SCROLLER,
} from '@build/util/constants/constants.js';
import Modal from '@build/components/modal/modal.js';
import FetchLoad from '@build/util/fetch-wrapper/fetch-load.js';
import AsyncQueue from '@build/util/fetch-wrapper/async-queue.js';
import { debugLog, doPushState } from '@build/util/helpers/helpers.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import InternalLinks from '@build/components/internal-links/internal-links.js';

/**
 * If we're not on the homepage on page load, then show the main content in modal and load homepage into background
 */
class HomepageLoader {
	public fetchEvents: any;
	public eventQueue: any;
	public classIdentifier: string;
	public card: Modal;
	public mainScrollEl: HTMLElement;
	private _fetchLoad: FetchLoad;

	constructor() {
		this.classIdentifier = 'HomepageLoader';

		// Get the main scroll container
		this.mainScrollEl = SCROLLER.querySelector(
			'.smoothscroll__content',
		) as HTMLElement;

		// If the pages main element does not have a data attribute of show-on-launch
		// or the main scroll container doesn't exist, then return
		if (MAIN.dataset?.modalId !== 'show-on-launch' || !this.mainScrollEl) {
			Modal.HOME_TITLE = document.title;
			return;
		}
		this.init();
	}

	async init() {
		// Remove the show-on-launch attribute to ensure it doesn't open again by accident
		// Though this init() should never be re-called
		delete MAIN.dataset.modalId;

		const modalUrl = window.location.href;
		// Pretend that our landing page (first item in history) was actually the home page
		window.history.replaceState(null, '', '/');
		// Then push that we loaded this current URL as the next page
		doPushState('fetch', MAIN.dataset, modalUrl);

		// Open a new Modal and shift the current page into it
		const modal = new Modal(MAIN.dataset, true);
		// loadedFromUrl = true as this was a full page request at this new URL
		modal.addExistingContent(this.mainScrollEl.children, true);
		modal.pageTitle = document.title;
		modal.open();

		// Initialize FetchLoad instance
		this._fetchLoad = new FetchLoad(this.classIdentifier, '');
		// Each event in this.fetchEvents will be handled by the AsyncQueue
		this.fetchEvents = FETCH_EVENTS;
		// Initialize AsyncQueue instance
		this.eventQueue = new AsyncQueue();
		// Add event listeners to the FetchLoad instance
		this.events();

		// Make the request to the homepage
		this._fetchLoad.url = '/';
		await this._fetchLoad.fetchContent();
	}

	/**
	 * The message to show if the homepage fails to load.
	 */
	private failMessage(): string {
		return `
			<div class="space-y-8 pt-96 px-8">
				<h3>Failed to load home page</h3>
				<p>Please refresh or <a href="/" aria-label="Try load home page again" data-ignore>try again</a></p>
			</div>
		`;
	}

	/**
	 * Attaches event listeners to the FetchLoad instance.
	 */
	async events() {
		await Promise.all(
			this.fetchEvents.map(async (eventName: string) => {
				this._fetchLoad.on(eventName, async (payload) => {
					await this.eventQueue.enqueue(() =>
						this.handleEvent(eventName, payload),
					);
				});
			}),
		);
	}

	/**
	 * Handles the FetchLoad events.
	 *
	 * @param {string} eventName
	 * @param {{ data?: string; error?: string }} payload
	 * @returns {Promise<void>}
	 */
	async handleEvent(
		eventName: string,
		payload: { data?: string; error?: string },
	): Promise<void> {
		return new Promise<void>((resolve) => {
			switch (eventName) {
				case 'preFetch':
					this.preFetchEvent(resolve);
					break;

				case 'duringFetch':
					this.duringFetchEvent(resolve);
					break;

				case 'afterFetchSuccess':
					this.afterFetchSuccessEvent(resolve, payload.data);
					break;

				case 'afterContentLoad':
					this.afterContentLoadEvent(resolve);
					break;

				case 'fetchFailure':
					this.fetchFailureEvent(resolve, payload.error);
					break;

				default:
					resolve();
					break;
			}
		});
	}

	preFetchEvent(resolve: () => void) {
		debugLog(`${this.classIdentifier}: Before fetching content...`);
		resolve();
	}

	duringFetchEvent(resolve: () => void) {
		debugLog(`${this.classIdentifier}: Fetching content...`);
		resolve();
	}

	async afterFetchSuccessEvent(resolve: () => void, data: string = '') {
		debugLog(`${this.classIdentifier}: Content fetched successfully`);

		const parser = new DOMParser();
		const parsedContent = parser.parseFromString(data, 'text/html');
		const homeTitle =
			parsedContent
				.querySelector('meta[name="title"]')
				?.getAttribute('content') || '';
		if (homeTitle) {
			Modal.HOME_TITLE = homeTitle;
		}
		// Only replace the content within the scroll container so we don't have to recreate the root SmoothScroll
		const homeContent = parsedContent.querySelector(
			'.smoothscroll__content',
		) as HTMLElement;
		this.mainScrollEl.innerHTML = homeContent?.innerHTML || this.failMessage();
		EventDispatcher.dispatch('global', 'contentAdded');
		InternalLinks.markAllAsInactive();
		resolve();
	}

	afterContentLoadEvent(resolve: () => void) {
		debugLog(`${this.classIdentifier}: New content loaded`);
		resolve();
	}

	fetchFailureEvent(resolve: () => void, error?: string) {
		debugLog(
			`${this.classIdentifier}: Failed to fetch content: ${error}`,
			'error',
		);
		this.mainScrollEl.innerHTML = this.failMessage();
		resolve();
	}
}

export default HomepageLoader;
