/**
 * EventDispatcher class.
 * Allows us to subscribe to and dispatch events.
 * @static - The class uses a static property and static methods, meaning they belong to the class itself rather than instances of the class.
 * @example
  // Subscribe to an event
  EventDispatcher.subscribe(
		'className',
		'eventName',
		() => {
  	console.log('Event triggered');
  });
  // Dispatch an event
  EventDispatcher.dispatch('className', 'eventName');
  // Output: 'Event triggered'
*/
class EventDispatcher {
	static listeners: { [event: string]: (() => void)[] } = {};

	static subscribe(
		classIdentifier: string,
		event: string | string[],
		callback: (eventName: string) => void,
	) {
		const events = Array.isArray(event) ? event : [event];

		events.forEach((ev) => {
			const namespacedEvent = `${classIdentifier}_${ev}`;

			if (!EventDispatcher.listeners[namespacedEvent]) {
				EventDispatcher.listeners[namespacedEvent] = [];
			}

			EventDispatcher.listeners[namespacedEvent].push(callback as () => void);
		});
	}

	static off(
		classIdentifier: string,
		event: string | string[],
		callback: (eventName: string) => void,
	) {
		const events = Array.isArray(event) ? event : [event];
		events.forEach((ev) => {
			const namespacedEvent = `${classIdentifier}_${ev}`;
			EventDispatcher.listeners[namespacedEvent] = EventDispatcher.listeners[
				namespacedEvent
			]?.filter((cb) => cb !== callback);
		});
	}

	static dispatch(classIdentifier: string, event: string) {
		// Bump off all the execution of listeners to a the JS execution queue
		setTimeout(() => {
			const namespacedEvent = `${classIdentifier}_${event}`;
			const globalEvent = `global_${event}`;

			if (EventDispatcher.listeners[namespacedEvent]) {
				EventDispatcher.listeners[namespacedEvent].forEach(
					(callback: (eventName: string) => void) => {
						callback(event);
					},
				);
			}

			if (EventDispatcher.listeners[globalEvent]) {
				EventDispatcher.listeners[globalEvent].forEach(
					(callback: (eventName: string) => void) => {
						callback(event);
					},
				);
			}
		});
	}
}

export default EventDispatcher;
