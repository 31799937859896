import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Reel from '@build/components/reel/reel.js';

/**
 * Initialises any Reel components now and on contentAdded
 */
class ReelInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initReels();
		});

		this.initReels();
	}

	private initReels() {
		const els = document.querySelectorAll('.reel-modal:not([data-init])');
		els.forEach((el) => {
			const element = el as HTMLElement;
			new Reel(element);
		});
	}
}

export default ReelInit;
