import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Modal from '@build/components/modal/modal.js';
import { SearchBits } from '@build/util/constants/constants.js';
import Search from '@build/components/search/search.js';

class TagsFilter {
	private viewResultsBtn: HTMLButtonElement;
	private updateResultsBtn: HTMLButtonElement;
	private boundSearchUpdateTrigger: () => void;
	private searchBits: SearchBits;

	constructor(private element: HTMLElement) {
		this.element.dataset.init = 'true';

		// The View results button
		this.viewResultsBtn = this.element.querySelector(
			'.c-tags-filter__view-results',
		) as HTMLButtonElement;
		if (!this.viewResultsBtn) return;

		// If we've opened this form via the search page tag filters button
		// then instead of opening a search modal, we're instead going to
		// just update the search URL of the already open search modal
		const modalContent = this.element.closest('.modal__content') as HTMLElement;
		if (modalContent?.dataset.searchUrl) {
			// Keep the rest of the search URL parameters to ensure we don't clear their existing search
			this.searchBits = Search.urlToBits(modalContent?.dataset.searchUrl);
			this.configureTagsFilterAsPartOfSearchForm();
		} else {
			this.searchBits = Search.urlToBits('');
		}

		// Hook up tags to update the search URL of the view results button
		this.element.querySelectorAll('.c-tags-filter__tag input').forEach((el) => {
			el.addEventListener('change', this.updateSearchUrl.bind(this));
		});
	}

	private configureTagsFilterAsPartOfSearchForm() {
		// The Update results button
		this.updateResultsBtn = this.element.querySelector(
			'.c-tags-filter__update-search',
		) as HTMLButtonElement;
		if (!this.updateResultsBtn) return;

		// Pre-select tags if there were already any selected from URL
		this.searchBits.tags.forEach((tag) => {
			const input = this.element.querySelector(
				`input[value="${tag}"]`,
			) as HTMLInputElement;
			if (input) input.checked = true;
		});

		// Function to tell the search modal we've updated its searchUrl to update the form/results
		const searchUpdateTrigger = () => {
			EventDispatcher.dispatch('global', 'searchUpdated');
			EventDispatcher.off('Modal', 'close', this.boundSearchUpdateTrigger);
		};
		this.boundSearchUpdateTrigger = searchUpdateTrigger.bind(this);

		// Swap out the button that's handled by InternalLinks for one that we'll handle here
		this.viewResultsBtn.classList.add('hidden');
		this.updateResultsBtn.classList.remove('hidden');
		this.updateResultsBtn.addEventListener('click', (ev) => {
			ev.preventDefault();
			// Update the searchUrl data attr and tell the search modal to refresh
			const searchModal = Modal.getModal(Modal.getLastModalIndex() - 1);
			searchModal?.modalContent.setAttribute(
				'data-search-url',
				this.getSearchUrl(),
			);
			// We need the modal to have closed before we kick off search, otherwise search will
			// try to pushState the new search URL but trying to close this here modal will undo/pop that
			EventDispatcher.subscribe(
				'Modal',
				'close',
				this.boundSearchUpdateTrigger,
			);
			Modal.getLastModal()?.close();
		});
	}

	/**
	 * Gets the search request based on the selected tags
	 */
	private getSearchUrl() {
		const checkedInputs = Array.from(
			this.element.querySelectorAll('.c-tags-filter__tag input:checked'),
		) as HTMLInputElement[];
		this.searchBits.tags = checkedInputs.map((el) => el.value);
		return Search.bitsToUrl(this.searchBits);
	}

	/**
	 * Updates the search request based on the selected tags
	 */
	private updateSearchUrl() {
		this.viewResultsBtn.dataset.modalSearchUrl = this.getSearchUrl();
	}
}

export default TagsFilter;
