import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Search from '@build/components/search/search.js';

/**
 * Initialises any search components now and on contentAdded
 */
class SearchInit {
	constructor() {
		EventDispatcher.subscribe('Modal', 'contentAdded', () => {
			this.initSearches();
		});

		this.initSearches();
	}

	private initSearches() {
		const searches = document.querySelectorAll(
			'.c-search-results:not([data-init])',
		);
		searches.forEach((search) => {
			if (!search.closest('[data-ignore]')) {
				new Search(search as HTMLElement);
			}
		});
	}
}

export default SearchInit;
