import Modal from '@build/components/modal/modal.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Saved from '@build/components/saved/saved.js';
import {
	doPushState,
	doReplaceState,
	getLastState,
	setLastState,
} from '@build/util/helpers/helpers.js';
import SmoothScroll from '@build/components/smooth-scroll/smooth-scroll.js';
import Toast from '@build/components/toast/toast.js';

/**
 * A component that handles internal links to open in modals
 */
class InternalLinks {
	private boundClickHandler: (e: MouseEvent) => void;

	constructor() {
		// Create a single function reference to allow removing the listener
		this.boundClickHandler = this.clickHandler.bind(this);

		// Subscribe to the contentAdded event and initialize any links loaded into the DOM
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.addLinkHandlers();
		});
		EventDispatcher.subscribe('Reel', 'slidesUpdated', () => {
			this.addLinkHandlers();
		});

		// Initialize the links
		this.addLinkHandlers();

		// Action a jump link if we just finished loading a page with a #
		EventDispatcher.subscribe('global', 'urlLoaded', () => {
			const wasActioned = InternalLinks.actionJumpLink(window.location.href);
			if (!wasActioned) {
				Modal.setFocus();
			}
		});

		// This is triggered anytime the browser back/forward is pressed
		// But also on history.back or history.go - i.e. modal.close() and the home button below.
		// And also when the URL is manually changed to the SAME path but with a change in #
		// Any time a modal opens we've tracked the index of it in the history state.
		// If we've gone back, then the latest state index will be smaller than the latest modal index.
		// In which case we close the modal(s) higher than the index we've gone back to (which in the
		// case of home button will be all of them)
		// If the forward button was pressed, then history.state should give us the modal type/params/url
		// which we use to recreate that modal (and re-fetch the content if "fetch" type).
		window.addEventListener('popstate', (event) => {
			// Reset aria-current
			InternalLinks.markAllAsInactive();

			// If only the hash is changing then we shouldn't need to do any forward/back magic
			// Just action the jump link on the current page/modal
			const lastModalIndex = Modal.getLastModalIndex();
			const isManualHashChangeOrBackToHome = !event.state;
			const isForwardOrBackWithinSameModal =
				event.state?.modalIndex >= lastModalIndex;
			const isBackOrForwardWithinHomePage =
				typeof event.state?.modalIndex === 'undefined' && lastModalIndex === -1;

			// Any of these cases MAY be a # change instead of modal close or open event
			if (
				isManualHashChangeOrBackToHome ||
				isForwardOrBackWithinSameModal ||
				isBackOrForwardWithinHomePage
			) {
				// But we need to check if the URL we're going to is the SAME as that we were on
				// And that the new URL has a # target
				let newUrl = window.location.href;
				if (event.state && event.state.modalIndex > lastModalIndex) {
					// If they've hit FORWARD then the new URL will be that in the given state
					newUrl = event.state.href || '/';
				}
				// If we don't have a last state href we're either homepage or an internal modal
				let lastUrl: string = getLastState()?.href || '/';
				if (lastUrl === '/' || lastUrl.indexOf('http') !== 0) {
					// Ensure the URL is absolute so it's comparable to current href
					lastUrl = InternalLinks.getAnchorUrl(lastUrl)?.href || '/';
				}
				const lastUrlBase =
					lastUrl.indexOf('#') > -1
						? lastUrl.substring(0, lastUrl.indexOf('#'))
						: lastUrl;
				const newUrlBase =
					newUrl.indexOf('#') > -1
						? newUrl.substring(0, newUrl.indexOf('#'))
						: newUrl;

				if (lastUrlBase === newUrlBase && window.location.hash) {
					// We're just scrolling within current page, no open/close modals
					doReplaceState(window.location.href);
					InternalLinks.actionJumpLink(window.location.href);
					// We're not actually closing, re-enable the back button etc
					const modal = Modal.getLastModal();
					if (modal) modal.isClosing = false;
					return;
				}
			}

			// We're opening or closing modals
			setLastState(event.state);
			if (
				!event.state ||
				typeof event.state.modalIndex === 'undefined' ||
				event.state.modalIndex <= lastModalIndex
			) {
				// Back, or modal closed, or Home
				// Close modals beyond this current index (modalIndex + 1), or all if there's no previous state
				Modal.closeModals(
					event.state?.modalIndex !== undefined
						? event.state.modalIndex + 1
						: 0,
				);
			} else {
				// Forward
				this.loadModal(
					event.state?.type,
					event.state?.dataset,
					event.state?.href,
				);
			}
		});
	}

	private getElements(): (HTMLAnchorElement | HTMLButtonElement)[] {
		const documentLinks = Array.from(
			// These sorts of links should not open internally / in modal
			document.querySelectorAll(
				'a:not([target="_blank"]):not([data-ignore]):not([download]):not([data-init]):not([href^="mailto"]):not([href^="tel"]), button:not([data-ignore]):not([data-init])',
			),
		) as (HTMLAnchorElement | HTMLButtonElement)[];
		// Skip any links that are external URLs or are within content that isn't ready to be processed
		return documentLinks.filter((link) => {
			// Within a section to ignore e.g. Poll messages that aren't shown yet
			if (link.closest('.internal-links--ignore') !== null) return false;
			// If we've got a modal target all good
			if (link.dataset.modalTarget) return true;
			// Otherwise check we've got a valid internal link (if not then skip this)
			return (
				InternalLinks.getAnchorUrl(InternalLinks.getAnchorString(link))
					?.hostname === window.location.hostname
			);
		});
	}

	private static getAnchorUrl(link: string): URL | null {
		try {
			return new URL(
				link,
				`${window.location.protocol}//${window.location.host}`,
			);
		} catch (e) {
			return null;
		}
	}

	private static getAnchorString(link: HTMLElement) {
		return (
			link.getAttribute('href') || link.getAttribute('data-jump-link') || ''
		);
	}

	/**
	 * Attaches click event listeners to the internal links.
	 */
	addLinkHandlers() {
		this.getElements().forEach((el) => {
			const element = el as HTMLElement;
			const href = element.getAttribute('href');
			// Determine if we're opening modal content already on the page, or fetching a page
			const type = this.getLinkType(element);
			if (type === 'save' && href) {
				const isSaved = Saved.isSaved(href);
				element.classList.toggle('saved--active', isSaved);
			}

			element.dataset.init = 'true';

			if (
				element.getAttribute('target') !== '_blank' &&
				element.dataset.modalTarget
			) {
				element.setAttribute('aria-haspopup', 'true');
			}

			// The boundClickHandler is a consistent function signature so it won't be added multiple times
			element.addEventListener('click', this.boundClickHandler);
		});
	}

	private getLinkType(element: HTMLElement) {
		const el = element;
		const href = el.getAttribute('href');
		let type = el.dataset.modalTarget ? 'modal' : 'fetch';
		if (el.dataset.type === 'copy') {
			type = 'copy';
		} else if (el.getAttribute('data-jump-link')) {
			type = 'jump';
		} else if (href?.startsWith('/save/')) {
			type = 'save';
		} else if (href?.startsWith('/search/')) {
			type = 'modal';
			el.dataset.modalTarget = 'search-results';
			el.dataset.modalContentClasses =
				'modal__content--gray-btns c-search-modal--autoload';
			el.dataset.modalSearchUrl = href;
			el.dataset.modalPageTitle = 'Search | LoveBetter';
		}
		return type;
	}

	/**
	 * Note - don't add this as a listener - use this.boundClickHandler
	 */
	private clickHandler(e: MouseEvent) {
		e.preventDefault();
		const element = e.currentTarget as HTMLElement;
		const href = element.getAttribute('href');
		const type = this.getLinkType(element);

		if (
			element.classList.contains('modal--close-all') ||
			(type === 'fetch' && InternalLinks.isHome(href))
		) {
			window.history.go(-Modal.getLastModalIndex() - 1);
			return;
		}

		if (type === 'copy') {
			const data = {
				title: element.dataset.title || document.title,
				text: element.dataset.text || '',
				url: element.dataset.url || window.location.href,
			};

			this.copyUrl(data);

			return;
		}

		if (type === 'save' && href) {
			Saved.toggleSave(href);
			return;
		}

		// Invalid state, perhaps we forgot to add a data-ignore on one of our buttons?
		if (type === 'fetch' && !href) {
			console.warn('Button or Link handled by InternalLinks has invalid state');
			return;
		}

		// Handle # links if they're targeting within the current modal/page
		if (type === 'fetch' || type === 'jump') {
			const wasActioned = InternalLinks.actionJumpLink(
				InternalLinks.getAnchorString(element),
			);
			if (wasActioned) return;
			// If it were a jump button and we didn't find the target, then don't go further
			if (type === 'jump') {
				console.warn('Jump Button handled by InternalLinks has no matching id');
				return;
			}
		}

		if (
			element.dataset.modalTarget &&
			!document.querySelector(
				`[data-modal-id="${element.dataset.modalTarget}"]`,
			)
		) {
			console.warn(
				'Button or Link handled by InternalLinks has no matching modal target',
			);
			return;
		}

		if (type === 'modal' && element.getAttribute('data-focus-search-if-open')) {
			const modal = Modal.getLastModal();
			const input = modal?.modalWrapper.querySelector(
				'input[name="keywords"]',
			) as HTMLInputElement;
			if (input) {
				input.focus();
				return;
			}
		}

		if (element.getAttribute('aria-current') === 'page') return;

		this.markAsActive(element);
		doPushState(type, element.dataset, href);
		this.loadModal(type, element.dataset, href);
	}

	/**
	 * Attempts to jump to the #/jump link (if provided, and if matching data-anchor-id exists)
	 * @param link string url | HTMLAnchorElement | HTMLButtonElement with possible data-jump-link
	 * @returns boolean whether the jump link was actioned or not
	 */
	public static actionJumpLink(link: string) {
		const url = InternalLinks.getAnchorUrl(link);
		// Either the pathname is same as current, or there's no pathname just #
		if (
			url &&
			url.hash &&
			(url.pathname === window.location.pathname || link.indexOf('#') === 0)
		) {
			const id = url.hash.substring(1);
			const modal = Modal.getLastModal();
			// NOTE we're using data-anchor-id instead of element.id because each page could be rendered multiple times in the doc
			const anchor = (modal ? modal.modalWrapper : document).querySelector(
				`[data-anchor-id="${id}"]`,
			) as HTMLElement;
			if (anchor) {
				const scroller = modal
					? modal.smoothscroll
					: SmoothScroll.getLastSmoothScroll();
				scroller.lenis.scrollTo(anchor);
				const prevTabindex = anchor.getAttribute('tabindex');
				// Hash links not only scroll, they move the keyboard focus to the target
				// but we cannot do that programmatically without it being a focusable element
				const state = { ...window.history.state };
				state.href = `${state.href?.split('#')[0]}#${id}`;
				window.history.replaceState(state, '', state.href);
				anchor.setAttribute('tabindex', '0');
				anchor.focus();
				// Reinstate previous tabindex value
				if (prevTabindex) anchor.setAttribute('tabindex', prevTabindex);
				else anchor.removeAttribute('tabindex');
				return true;
			}
		}
		return false;
	}

	private loadModal(type: string, dataset: DOMStringMap, href: string | null) {
		// For modal content, we need to get the target element and add it to the Modal instance
		if (type === 'modal') {
			// The target element to add to the Modal instance
			const targetQuery = dataset.modalTarget;
			const target = document.querySelector(`[data-modal-id="${targetQuery}"]`);

			// Create the modal instance
			const modal = new Modal(dataset);
			// Add the content to the Modal instance
			modal.addContent(target?.innerHTML || '');
			// And open it
			modal.open();
		} else if (href) {
			// For fetching content, we need to update the URL in FetchHandler and fetch the content
			// Create a new Modal instance before fetching content
			const modal = new Modal(dataset, true);
			// Set the pushState to the the url for this Modal instance
			modal.open();
			modal.loadUrl(href);
		}
	}

	private async copyUrl(data: any) {
		// Check if the device supports the Web Share API and the data is valid
		if (navigator.share && navigator.canShare(data)) {
			try {
				await navigator.share(data);
			} catch (err) {
				if ((err as Error).name !== 'AbortError') {
					Toast.show(
						'Failed to share link, try copying the URL from your browser',
					);
				}
				console.error(err);
			}
		}
		// Fallback to clipboard API
		else {
			try {
				await navigator.clipboard.writeText(data.url);
				Toast.show('Link copied!');
			} catch (err) {
				Toast.show(
					'Failed to copy link, try copying the URL from your browser',
				);
				console.error(err);
			}
		}
	}

	/**
	 * Marks a link as active.
	 */
	private markAsActive(link: HTMLElement) {
		InternalLinks.markAllAsInactive();
		link.setAttribute('aria-current', 'page');
	}

	/**
	 * Marks all links as inactive.
	 */
	public static markAllAsInactive() {
		document
			.querySelectorAll('[aria-current="page"]')
			.forEach((el) => el.setAttribute('aria-current', 'false'));
	}

	public static isHome(href: string | null): boolean {
		if (typeof href !== 'string') return false;
		const url = InternalLinks.getAnchorUrl(href);
		return !!(url && url.pathname === '/' && window.location.host === url.host);
	}
}

export default InternalLinks;
