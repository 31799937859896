import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import InShort from './in-short.js';

/**
 * Initialises any In Short components now and on contentAdded
 */
class InShortInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initInShorts();
		});

		this.initInShorts();
	}

	private initInShorts() {
		const els = document.querySelectorAll('.in-short:not([data-init])');
		els.forEach((el) => {
			if (el.closest('.in-short--ignore') === null) {
				const element = el as HTMLElement;
				new InShort(element, element.dataset.autoplay !== undefined);
			}
		});
	}
}

export default InShortInit;
