/**
 * A queue that runs tasks asynchronously in order.
 * Tasks are run one at a time, and the next task is not run until the previous one finishes.
 * @example
  const queue = new AsyncQueue();
  queue.enqueue(async () => {
  	await doSomething();
  });
  queue.enqueue(async () => {
  	await doSomethingElse();
  });
  queue.enqueue(async () => {
  	await doAnotherThing();
  });
 */
class AsyncQueue {
	private queue: (() => Promise<void>)[] = [];
	private running = false;

	async enqueue(task: () => Promise<void>): Promise<void> {
		this.queue.push(task);
		if (!this.running) {
			await this.processQueue();
		}
	}

	private async processQueue(): Promise<void> {
		this.running = true;
		while (this.queue.length > 0) {
			const task = this.queue.shift();
			// eslint-disable-next-line no-await-in-loop
			await task!();
		}
		this.running = false;
	}
}

export default AsyncQueue;
