import '@styles/styles.scss';
import SmoothScroll from '@build/components/smooth-scroll/smooth-scroll.js';
import InternalLinks from '@build/components/internal-links/internal-links.js';
import BannerInit from '@build/components/banner/init.js';
import Modal from '@build/components/modal/modal.js';
import {
	SCROLLER,
	updateMotionValues,
} from '@build/util/constants/constants.js';
import FooterAnimation from '@build/components/footer-animation/footer-animation.js';
import PollInit from '@build/components/poll/init.js';
import QuickExit from '@build/components/quick-exit/quick-exit.js';
import ThemeChanger from '@build/components/theme-changer/theme-changer.js';
import AccessibilityToggle from '@build/components/accessibility-toggle/accessibility-toggle.js';
import Nav from '@build/components/nav/nav.js';
import HomepageLoader from '@build/components/homepage-loader/homepage-loader.js';
import MediaFilterWheel from '@build/components/media-filter/media-filter.js';
import Marquee from '@build/components/marquee/marquee.js';
import InShortInit from '@build/components/in-short/init.js';
import DailyAffirmation from '@build/components/daily-affirmation/daily-affirmation.js';
import AccessibilitySettings from '@build/util/accessibility/accessibility.js';
import SavedInit from '@build/components/saved/init.js';
import CarouselInit from '@build/components/carousel/init.js';
import ReelInit from '@build/components/reel/init.js';
import ReelCarouselInit from '@build/components/reel-carousel/init.js';
import SearchInit from '@build/components/search/init.js';
import AffirmationsInit from '@build/components/affirmations/init.js';
import TagsFilterInit from '@build/components/tags-filter/init.js';
import Toast from '@build/components/toast/toast.js';
import QuizInit from '@build/components/quiz/init.js';
import LogoColour from '@build/components/logo-colour/logo-colour.js';
import IconExplosionInit from '@build/components/icon-explosion/init.js';
import ArticleSpacing from '@build/components/article-spacing/article-spacing.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

// Global variables we get from the .env
// These are set in the main Page.ss file via Silverstripe hooks
declare global {
	const BASE_URL: string;
	const ENVIRONMENT: string;
	const GTM_ID: string;
	const RECAPTCHA_KEY: string;
	const AFFIRMATION_INIT: string;
}

// Wait for the DOM to load before running any JS
(() => {
	// Fetch the user's accessibility settings
	AccessibilitySettings.checkBrowserSettings();
	// Set the motion values based on the user's accessibility settings
	updateMotionValues();
	// Daily affirmation / page loader
	new DailyAffirmation();
	// Initiate Lenis on the main container
	new SmoothScroll(SCROLLER);
	// Add fetch loading to all internal links
	new InternalLinks();
	// Set observer for logo colour change
	new LogoColour();
	// Theme changer
	new ThemeChanger();
	// No Motion High Contrast toggle
	new AccessibilityToggle();
	// Add any banners to the page
	new BannerInit();
	// Initiate the close modal event
	Modal.init();
	// Initiate the polls
	new PollInit();
	// Initiate quick exit link
	new QuickExit();
	// Initiate the nav behaviour
	new Nav();
	// Load the homepage behind the scenes
	new HomepageLoader();
	// The 360 media filter wheel
	new MediaFilterWheel();
	// Marquee elements
	new Marquee();
	// Initiate in short components
	new InShortInit();
	// Initiate saved listing
	new SavedInit();
	// Initiate carousels
	new CarouselInit();
	// Reels
	new ReelInit();
	new ReelCarouselInit();
	// Search
	new SearchInit();
	// Affirmations
	new AffirmationsInit();
	// Tags filter
	new TagsFilterInit();
	// Toast
	new Toast();
	// Animations in footer
	new FooterAnimation();
	// Quizzes
	new QuizInit();
	// Icon poll animation
	new IconExplosionInit();
	// Article spacing
	new ArticleSpacing();

	// Google Tag Manager tracking when a new URL is loaded through a modal
	EventDispatcher.subscribe('global', 'urlLoaded', () => {
		const gtag: any = window.gtag || [];
		if (GTM_ID && gtag && typeof gtag === 'function') {
			gtag('event', 'page_view', {
				page_title: document.title,
				page_path: window.location.pathname,
			});
		}
	});
})();
