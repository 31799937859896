import gsap from 'gsap';
import {
	NAV,
	PRIMARY_NAV,
	SECONDARY_NAV,
} from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Modal from '@build/components/modal/modal.js';
import Saved from '@build/components/saved/saved.js';

/**
 * A component that shows/hides the navigation bar, and toggles between primary and secondary navigation.
 */
class Nav {
	public classIdentifier: string;

	constructor() {
		if (!NAV) return;

		this.classIdentifier = 'Nav';

		const saveButton = NAV.querySelector('button[data-save-current-page]');

		// Subscribe to the Modal events
		// "opening" might happen before content is loaded, so we may need the "contentAdded" for the saveUrl
		EventDispatcher.subscribe(
			'global',
			['opening', 'closing', 'contentAdded'],
			async (event): Promise<void> => {
				if (event === 'opening' || event === 'closing') {
					// Slide the nav out/down
					await Nav.slideDown();
				}

				// If InternalLinks has determined to show the secondary nav, toggle the links and slide it up
				const modal = Modal.getLastModal();
				if (modal && modal.secondaryNav && modal.saveUrl) {
					// Update heart to show if this page is already saved or not
					saveButton?.classList.toggle(
						'saved--active',
						Saved.isSaved(modal.saveUrl),
					);
					// Turn the menu black if we've set it with a data attribute
					NAV.classList.toggle('nav-main--black', modal.secondaryNavBlack);
					this.showSecondaryNav();
					await Nav.slideUp();
				} else if (!modal && event === 'closing') {
					// If there is no next modal then show the primary nav
					if (NAV.classList.contains('nav-main--black'))
						NAV.classList.remove('nav-main--black');
					this.showPrimaryNav();
					await Nav.slideUp();
				}
			},
		);

		saveButton?.addEventListener('click', () => {
			const modal = Modal.getLastModal();
			if (modal && modal.saveUrl) {
				saveButton?.classList.toggle(
					'saved--active',
					Saved.toggleSave(modal.saveUrl),
				);
			}
		});
	}

	/**
	 * Slide the nav down
	 */
	static async slideDown() {
		await gsap.to(NAV, {
			autoAlpha: 0,
			y: NAV.offsetHeight,
		});
	}

	/**
	 * Slide the nav up
	 */
	static async slideUp() {
		await gsap.to(NAV, {
			autoAlpha: 1,
			y: 0,
		});
	}

	/**
	 * Show the primary navigation by toggling classes
	 */
	showPrimaryNav() {
		NAV.querySelector('[data-home-link]')?.setAttribute('aria-current', 'page');
		PRIMARY_NAV.forEach((element) => element.classList.remove('hidden'));
		PRIMARY_NAV.forEach((element) => element.classList.add('block'));
		SECONDARY_NAV.forEach((element) => element.classList.remove('block'));
		SECONDARY_NAV.forEach((element) => element.classList.add('hidden'));
	}

	/**
	 * Show the secondary navigation by toggling classes
	 */
	showSecondaryNav() {
		PRIMARY_NAV.forEach((element) => element.classList.add('hidden'));
		PRIMARY_NAV.forEach((element) => element.classList.remove('block'));
		SECONDARY_NAV.forEach((element) => element.classList.add('block'));
		SECONDARY_NAV.forEach((element) => element.classList.remove('hidden'));
	}
}

export default Nav;
