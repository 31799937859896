import EventDispatcher from './event-dispatcher.js';

type Listener = {
	event: string;
	callback: (payload: any) => void;
};

/**
 	Custom Fetch API wrapper to handle fetch events
 	Events trigger during fetch:
 	1. Before a request is made; preFetch
 	2. While a fetch is happening; duringFetch
 	3. After a fetch has successfully received data; afterFetchSuccess
 	4. After data has finished loading; afterContentLoad
 	5. Whenever a fetch fails; fetchFailure
*/
class FetchLoad {
	private _url: string;
	private _classIdentifier: string;
	private _listeners: Listener[];

	/*
		Getter and setter for URL
		- Setter is used to update the URL before fetching content
		- Getter is used to get the URL to fetch content
	*/
	get url(): string {
		return this._url;
	}

	set url(url: string) {
		this._url = url;
	}

	/**
	 * Creates an instance of FetchLoad.
	 * @param url - The URL from which to fetch the content.
	 * TODO; add a check for content type, incase it should load json or html
	 * TODO; add a check to see if the URL is valid?
	 * TODO; add a check to see if the URL is an internal link?
	 */
	constructor(classIdentifier: string, url: string) {
		this._url = url;
		this._classIdentifier = classIdentifier;
		this._listeners = [];
	}

	/**
	 * Triggers a fetch request to fetch the content from the specified URL.
	 */
	async fetchContent() {
		// Trigger a preFetch event
		this.fetchEvent('preFetch');

		try {
			// Trigger a duringFetch event
			this.fetchEvent('duringFetch');
			// Fetch content
			const response = await fetch(this._url);

			// If the response is not OK, trigger a fetchFailure event
			if (!response.ok) {
				this.fetchEventFailure(response.statusText);
				return;
			}

			// Check content type matches expected
			// We can make this a parameter if we ever need something different
			const contentType = response.headers.get('Content-Type');
			if (
				typeof contentType !== 'string' ||
				contentType.indexOf('text/html') !== 0
			) {
				this.fetchEventFailure('Unexpected content type');
				return;
			}

			const data = await response.text();
			// Trigger an afterFetchSuccess event
			this.fetchEvent('afterFetchSuccess', { data });
			// Load the new content
			this.loadNewContent(data);
		} catch (error) {
			// Trigger a fetchFailure event
			this.fetchEventFailure(error);
		}
	}

	private loadNewContent(data: any) {
		// Trigger an afterContentLoad event
		this.fetchEvent('afterContentLoad', { data });
	}

	private fetchEventFailure(error: any) {
		// Trigger a fetchFailure event
		this.fetchEvent('fetchFailure', { error });
	}

	/**
	 * Custom event handler.
	 * @param eventName - The name of the event to trigger.
	 * @param payload - Optional payload to pass along with the event.
	 */
	private fetchEvent(eventName: string, payload?: any) {
		const namespacedEvent = `${this._classIdentifier}_${eventName}`;

		this._listeners.forEach((listener) => {
			if (namespacedEvent === listener.event) {
				listener.callback(payload);
			}
		});
		EventDispatcher.dispatch(this._classIdentifier, eventName);
	}

	/**
	 * Registers an event listener for the specified event.
	 * @param event - The name of the event to listen for.
	 * @param callback - The callback function to be executed when the event is triggered.
	 */
	public on(event: string, callback: (payload?: any) => void) {
		const namespacedEvent = `${this._classIdentifier}_${event}`;

		this._listeners.push({
			event: namespacedEvent,
			callback,
		});
	}

	public destroy() {
		// Stop being able to call any callbacks
		this._listeners = [];
	}
}

export default FetchLoad;
