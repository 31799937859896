import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import AffirmationsList from './affirmations-list.js';

class AffirmationsInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initAffirmations();
		});

		this.initAffirmations();
	}

	private initAffirmations() {
		const els = document.querySelectorAll(
			'.c-affirmations-list:not([data-init])',
		);
		els.forEach((el) => {
			const element = el as HTMLElement;
			new AffirmationsList(element);
		});
	}
}

export default AffirmationsInit;
