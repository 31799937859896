import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

class Marquee {
	public classIdentifier: string;

	constructor() {
		this.classIdentifier = 'Marquee';

		// Subscribe to content additions
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			// Initialise any marquees
			this.init();
		});

		this.init();
	}

	init() {
		// Set up individual marquees
		const marquees = document.querySelectorAll(
			'.marquee-element:not([data-init])',
		);
		marquees.forEach((marquee) => this.setupMarquee(marquee));
	}

	setupMarquee(el: Element) {
		const marquee = el as HTMLElement;

		// The inner content
		const content = marquee.querySelector(
			'.marquee-element__content',
		) as HTMLElement;
		// Initialise the marquee so we don't run this again
		marquee.dataset.init = 'true';

		this.configureSize(marquee, content);

		let to: number | undefined;
		window.addEventListener('resize', () => {
			clearTimeout(to);
			to = setTimeout(() => {
				this.configureSize(marquee, content);
			}, 150);
		});

		// Add the class that runs it
		content.classList.add('marquee-element__content--running');

		// Add a paused modifier to the marquee when clicked
		marquee.addEventListener('click', () => {
			content.classList.toggle('marquee-element__content--paused');
		});
	}

	configureSize(marquee: HTMLElement, content: HTMLElement) {
		/**
		 * Check if marquee width fits within window width
		 * If not, double the content until it does
		 */
		// Width of the marquee
		const marqueeWidth = marquee.offsetWidth;

		// If the content is hidden, it will loop forever so kill it now
		if (content.offsetWidth < 1) return;

		// If the content is smaller than the marquee * 2, double it
		// We check if it's double the marquee width because the css animation runs to 50% and then resets
		while (content.offsetWidth <= marqueeWidth * 2) {
			// eslint-disable-next-line no-param-reassign
			content.innerHTML += content.innerHTML;
		}

		// A basic algorithm to calculate the duration of the marquee based on the marquee width and an optional offset
		const offset = (marqueeWidth * 4) / 1000;
		// eslint-disable-next-line no-param-reassign
		content.style.animationDuration = `${
			(content.offsetWidth / marqueeWidth) * offset
		}s`;
	}
}

export default Marquee;
