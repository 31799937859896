import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import TagsFilter from '@build/components/tags-filter/tags-filter.js';

/**
 * Initialises any tags filter components now and on contentAdded
 */
class TagsFilterInit {
	constructor() {
		EventDispatcher.subscribe('Modal', 'contentAdded', () => {
			this.initTagsFilter();
		});

		this.initTagsFilter();
	}

	private initTagsFilter() {
		const tagsFilterModals = document.querySelectorAll(
			'.c-tags-filter:not([data-init])',
		);
		tagsFilterModals.forEach((tagsFilter) => {
			if (!tagsFilter.closest('[data-ignore]')) {
				new TagsFilter(tagsFilter as HTMLElement);
			}
		});
	}
}

export default TagsFilterInit;
