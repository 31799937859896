import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Modal from '@build/components/modal/modal.js';

class QuickExit {
	private elements: any;

	constructor() {
		// Subscribe to the contentAdded event and initialize any links loaded into the DOM
		EventDispatcher.subscribe('global', 'contentAdded', () => this.init());
		this.init();
	}

	init() {
		this.elements = document.querySelectorAll('.quick-exit:not([data-init])');
		if (!this.elements.length) return;

		this.elements.forEach((el: HTMLElement) => {
			const element = el as HTMLElement;
			element.dataset.init = 'true';

			element.addEventListener('click', (e) => {
				e.preventDefault();

				const url = element.getAttribute('href');
				if (!url) return;

				// Remove the history state, so going back doesn't land on the page with a quick exit link
				window.history.go(-Modal.getLastModalIndex() - 1);
				// Redirect to the quick exit link
				window.setTimeout(() => {
					window.location.href = url;
				});
			});
		});
	}
}

export default QuickExit;
