import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import {
	ACCESSIBILITY,
	BODY,
	LOCAL_STORAGE,
	isMouseEnabled,
	updateMotionValues,
} from '@build/util/constants/constants.js';
import LocalStorageUtil from '@build/util/local-storage/local-storage.js';

class AccessibilityToggle {
	public classIdentifier: string;
	public pupils: NodeListOf<Element>;

	constructor() {
		this.classIdentifier = 'AccessibilityToggle';

		// Subscribe to content additions
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			// Initialise the toggles
			this.initListeners();
		});

		// Initial setup of the accessibility states incase the user has them set in their browser
		if (ACCESSIBILITY.reducedMotion) this.toggleReducedMotion(true);
		if (ACCESSIBILITY.highContrast) this.toggleHighContrast(true);

		// The pupils that will follow the cursor
		this.pupils = document.querySelectorAll('.eye-pupil');
		// We only want to add the mousemove event listener if the user has a mouse
		if (isMouseEnabled()) {
			document.addEventListener('mousemove', this.eyeMovement.bind(this));
		}
	}

	/**
	 * Initialise listeners for the accessibility toggle buttons
	 */
	initListeners() {
		const buttons = document.querySelectorAll(
			'.accessibility-toggle:not([data-init])',
		);

		if (!buttons) return;

		buttons.forEach((el) => {
			if (el.closest('[data-ignore]')) return;
			const btn = el as HTMLElement;
			btn.dataset.init = 'true';
			btn.addEventListener('click', (e) => {
				e.preventDefault();

				if (btn.classList.contains('accessibility-toggle--motion')) {
					this.toggleReducedMotion();
				} else if (btn.classList.contains('accessibility-toggle--contrast')) {
					this.toggleHighContrast();
				}
			});
		});
	}

	toggleHighContrast(initial: boolean = false) {
		if (!initial) {
			// Update global accessibility variables
			ACCESSIBILITY.highContrast = !ACCESSIBILITY.highContrast;
			// Update the motion values
			updateMotionValues();
			// Save the value to local storage
			LocalStorageUtil.setItem(
				LOCAL_STORAGE.contrast,
				ACCESSIBILITY.highContrast.toString(),
			);
		}

		// Add classes to toggle CSS variables
		BODY.classList.toggle('high-contrast');

		// Update the button text
		document
			.querySelectorAll('.accessibility-toggle--contrast')
			.forEach((el) => {
				const btn = el as HTMLButtonElement;
				btn.innerText = ACCESSIBILITY.highContrast
					? 'Turn off High Contrast'
					: 'Turn on High Contrast';
			});

		// Dispatch an event to notify other components of the change
		EventDispatcher.dispatch(
			this.classIdentifier,
			ACCESSIBILITY.highContrast ? 'contrast on' : 'contrast off',
		);
	}

	toggleReducedMotion(initial: boolean = false) {
		if (!initial) {
			// Update global accessibility variable
			ACCESSIBILITY.reducedMotion = !ACCESSIBILITY.reducedMotion;
			// Update the motion values
			updateMotionValues();
			// Save the value to local storage
			LocalStorageUtil.setItem(
				LOCAL_STORAGE.motion,
				ACCESSIBILITY.reducedMotion.toString(),
			);
		}

		// Add classes to toggle CSS variables
		BODY.classList.toggle('no-motion');

		// Update the button text
		document.querySelectorAll('.accessibility-toggle--motion').forEach((el) => {
			const btn = el as HTMLButtonElement;
			btn.innerText = ACCESSIBILITY.reducedMotion
				? 'Turn off Reduced Motion'
				: 'Turn on Reduced Motion';
		});

		// Dispatch an event to notify other components of the change
		EventDispatcher.dispatch(
			this.classIdentifier,
			ACCESSIBILITY.reducedMotion ? 'motion off' : 'motion on',
		);
	}

	eyeMovement(event: MouseEvent) {
		// If the user has requested reduced motion, don't move the pupils
		if (ACCESSIBILITY.reducedMotion) return;

		// the x and y postion of cursor
		const { clientX, clientY } = event;

		this.pupils.forEach((pupil) => {
			const el = pupil as HTMLElement;
			const rect = el.getBoundingClientRect();
			const x = `${(clientX - rect.left) / 300}px`;
			const y = `${(clientY - rect.top) / 200}px`;
			el.style.transform = `translate3d(${x},${y}, 0px)`;
		});
	}
}

export default AccessibilityToggle;
