import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import ReelCarousel from './reel-carousel.js';

/**
 * Initialises any Reel Carousel components now and on contentAdded
 */
class ReelCarouselInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initReelCarousels();
		});

		this.initReelCarousels();
	}

	private initReelCarousels() {
		const els = document.querySelectorAll(
			'[data-reel-carousel]:not([data-init])',
		);
		els.forEach((el) => {
			const element = el as HTMLElement;
			new ReelCarousel(element);
		});
	}
}

export default ReelCarouselInit;
