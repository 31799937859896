import gsap from 'gsap';
import { LOGO, SCROLLER } from '@build/util/constants/constants.js';
import Modal from '@build/components/modal/modal.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

class LogoColour {
	private darkLogos: NodeListOf<Element>;
	private lightLogos: NodeListOf<Element>;
	private observer: IntersectionObserver;

	constructor() {
		this.darkLogos = LOGO.querySelectorAll('.header__logo--dark');
		this.lightLogos = LOGO.querySelectorAll('.header__logo--light');

		EventDispatcher.subscribe('global', 'contentAdded', () => {
			// Reset to a dark logo when content is added and let the observer handle if it should be light or stay dark
			this.goDark();
			this.init();
		});

		EventDispatcher.subscribe('Modal', 'closing', () => {
			// Reset to a dark logo when modal is closed and let the observer handle if it should be light or stay dark
			this.goDark();
			this.init();
		});

		if (document.querySelector('.daily-affirmation')) {
			EventDispatcher.subscribe('DailyAffirmation', 'complete', () => {
				this.init();
			});
		} else {
			this.init();
		}
	}

	init() {
		// Disconnect the observer if it's already running so we don't keep stacking them
		if (this.observer) this.observer.disconnect();

		// Map to keep track of the intersection state of each element
		const intersectionStates = new Map();

		this.observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const { target } = entry;
					const { isIntersecting } = entry;

					// The logo is intersecting and hasn't been set to light yet
					if (isIntersecting && !intersectionStates.get(target)) {
						// Set the state to true so we don't keep setting the logo to light
						intersectionStates.set(target, true);
						// Set the logo to light
						this.goLight();
					}
					// The logo isn't intersecting and hasn't been reset to dark yet
					else if (!isIntersecting && intersectionStates.get(target)) {
						// Set the state to false so we don't keep setting the logo to dark
						intersectionStates.set(target, false);
						// Set the logo to dark
						this.goDark();
					}
				});
			},
			{
				root: null,
				rootMargin: `-${LOGO.offsetTop + LOGO.clientHeight / 2}px 0px -${window.innerHeight - LOGO.offsetTop - LOGO.clientHeight / 2}px 0px`,
				threshold: [0],
			},
		);

		// Get the modal content if it exists, otherwise use the main content
		const modal = Modal.getLastModal();
		const modalContent = modal
			? modal.modalWrapper
			: SCROLLER.querySelector('.smoothscroll__content');
		// Each trigger within the content
		const triggers = modalContent?.querySelectorAll('.logo-colour--dark');

		// Observe each trigger
		if (triggers) triggers.forEach((element) => this.observer.observe(element));
	}

	goLight() {
		gsap.to(this.lightLogos, { autoAlpha: 1 });
		gsap.to(this.darkLogos, { autoAlpha: 0 });
	}

	goDark() {
		gsap.to(this.darkLogos, { autoAlpha: 1 });
		gsap.to(this.lightLogos, { autoAlpha: 0 });
	}
}

export default LogoColour;
