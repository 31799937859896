import LocalStorageUtil from '@build/util/local-storage/local-storage.js';
import {
	ACCESSIBILITY,
	LOCAL_STORAGE,
} from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

/**
 * Accessibility settings
 * Taps into user's browser settings to determine if they have reduced motion or high contrast enabled
 */
class AccessibilitySettings {
	private static reducedMotion = true;
	private static highContrast = true;

	public static getReducedMotion(): boolean {
		return AccessibilitySettings.reducedMotion;
	}

	public static setReducedMotion(value: boolean): void {
		AccessibilitySettings.reducedMotion = value;
		ACCESSIBILITY.reducedMotion = value;
	}

	public static getHighContrast(): boolean {
		return AccessibilitySettings.highContrast;
	}

	public static setHighContrast(value: boolean): void {
		AccessibilitySettings.highContrast = value;
		ACCESSIBILITY.highContrast = value;
	}

	public static checkBrowserSettings(): void {
		const storedMotion = LocalStorageUtil.getItem(LOCAL_STORAGE.motion);
		const storedContrast = LocalStorageUtil.getItem(LOCAL_STORAGE.contrast);
		let showMessage = false;

		// Use local storage value to set the reduced motion setting
		if (storedMotion) {
			AccessibilitySettings.setReducedMotion(storedMotion === 'true');
		}
		// Otherwise use the user's browser setting and set it to local storage
		else {
			// prettier-ignore
			const userSetNoMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
			AccessibilitySettings.setReducedMotion(userSetNoMotion);
			// Set the users reduced motion setting to local storage
			LocalStorageUtil.setItem(
				LOCAL_STORAGE.motion,
				userSetNoMotion.toString(),
			);
			if (userSetNoMotion) showMessage = true;
		}

		// Use local storage value to set the high contrast setting
		if (storedContrast) {
			AccessibilitySettings.setHighContrast(storedContrast === 'true');
		}
		// Otherwise use the user's browser setting and set it to local storage
		else {
			// prettier-ignore
			const userSetHighContrast = !window.matchMedia('(prefers-contrast: no-preference)').matches;
			AccessibilitySettings.setHighContrast(userSetHighContrast);
			// Set the users high contrast setting to local storage
			LocalStorageUtil.setItem(
				LOCAL_STORAGE.contrast,
				userSetHighContrast.toString(),
			);
			if (userSetHighContrast) showMessage = true;
		}

		if (showMessage) {
			if (document.querySelector('.daily-affirmation')) {
				EventDispatcher.subscribe('DailyAffirmation', 'complete', () => {
					this.openAccessibilityMessage();
				});
			} else {
				this.openAccessibilityMessage();
			}
		}
	}

	public static openAccessibilityMessage() {
		setTimeout(() => {
			const a11yMessageOpener = document.querySelector(
				'.accessibility-message__opener',
			) as HTMLButtonElement;
			a11yMessageOpener?.click();
		}, 500);
	}
}

export default AccessibilitySettings;
