import gsap from 'gsap';
import { ACCESSIBILITY, HEADER } from '@build/util/constants/constants.js';

class HeaderScrollBack {
	private isVisible: boolean;
	private scrollThreshold: number;
	private buttons: NodeListOf<Element>;

	constructor() {
		// Do nothing if there is no header
		if (!HEADER) return;
		this.buttons = HEADER.querySelectorAll('.header__button');
		// truthy/falsy for the header visibility
		this.isVisible = true;
		// Point past we scroll before triggering
		this.scrollThreshold = 50;
	}

	init(lenis: any, hideHeader: boolean = false) {
		// If the current modal forces header to hide then don't set up a listener
		if (hideHeader) {
			this.slideUp();
			return;
		}

		// Listen to the scroller
		lenis.on('scroll', () => {
			// The current scroll position
			const scrollTop = lenis.targetScroll;

			// Past the threshold, header is visible, and scrolling down
			if (
				lenis.direction > 0 &&
				scrollTop > this.scrollThreshold &&
				this.isVisible
			) {
				this.slideUp();
			}
			// Header is not visible, and scrolling up
			else if (lenis.direction < 0 && !this.isVisible) {
				this.slideDown();
			}
		});
	}

	public slideUp() {
		this.isVisible = false;

		// Slide the header element up
		gsap.to(HEADER, {
			autoAlpha: 0,
			y: -HEADER.offsetHeight,
		});

		// Slightly stagger the buttons out for a parallax effect
		gsap.to(this.buttons, {
			y: -50,
			stagger: ACCESSIBILITY.reducedMotion ? 0 : 0.1,
		});
	}

	public slideDown() {
		this.isVisible = true;

		// Slide the header element back down
		gsap.to(HEADER, {
			autoAlpha: 1,
			y: 0,
		});

		// Slightly stagger the buttons in for a parallax effect
		gsap.to(this.buttons, {
			y: 0,
			stagger: ACCESSIBILITY.reducedMotion ? 0 : 0.1,
		});
	}
}

export default HeaderScrollBack;
