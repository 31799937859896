import gsap from 'gsap';

/**
 * Each modal has an instance of this back button
 * This class hides/shows the button as you scroll the page
 */
class BackButtonScrollback {
	public button: any;
	public isVisible: boolean;
	public scrollThreshold: number;
	lenis: any;
	private boundOnScroll: () => void;

	constructor(button: HTMLElement, lenis: any) {
		// Point past we scroll before triggering
		this.scrollThreshold = 50;
		this.button = button;
		this.lenis = lenis;
		// truthy/falsy for the button visibility
		this.isVisible = this.button.style.visibility !== 'hidden';
		// In slide-mode the modal itself doesn't scroll, so keep the back button visible
		if (this.button.closest('.modal__content--slide-mode')) {
			if (!this.isVisible) {
				this.slideDown();
			}
			return;
		}
		this.boundOnScroll = this.onScroll.bind(this);
		// Listen to the scroller
		this.lenis.on('scroll', this.boundOnScroll);
	}

	private onScroll() {
		const scrollTop = this.lenis.targetScroll;

		// Past the threshold, button is visible, and scrolling down
		if (
			this.lenis.direction > 0 &&
			scrollTop > this.scrollThreshold &&
			this.isVisible
		) {
			this.slideUp();
		}
		// Button is not visible, and scrolling up
		else if (this.lenis.direction < 0 && !this.isVisible) {
			this.slideDown();
		}
	}

	public destroy() {
		this.lenis.off('scroll', this.boundOnScroll);
	}

	private slideUp() {
		this.isVisible = false;

		gsap.set(this.button, {
			transition: 'none',
		});

		// Slide the button up
		gsap.to(this.button, {
			autoAlpha: 0,
			y: -(this.button.offsetHeight + this.button.offsetTop),
		});
	}

	private slideDown() {
		this.isVisible = true;

		// Slide the button back down
		gsap.to(this.button, {
			autoAlpha: 1,
			y: 0,
			clearProps: 'all',
		});
	}
}

export default BackButtonScrollback;
