import Quiz from '@build/components/quiz/quiz.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

/**
 * Initialises any quiz components now and on contentAdded
 */
class QuizInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initQuizzes();
		});

		this.initQuizzes();
	}

	private initQuizzes() {
		const quizzes = document.querySelectorAll('.c-quiz:not([data-init])');
		quizzes.forEach((quiz) => {
			if (!quiz.closest('[data-ignore]')) {
				new Quiz(quiz as HTMLElement);
			}
		});
	}
}

export default QuizInit;
