import Modal from '@build/components/modal/modal.js';
import { HistoryState } from '../constants/constants.js';

// Add a console log to the page if we're in dev mode
export function debugLog(
	message: any,
	type = 'log',
	data = null,
): Promise<void> {
	return new Promise<void>((resolve) => {
		if (ENVIRONMENT === 'dev') {
			// console.group();
			if (type === 'error') console.error(message);
			else if (type === 'warn') console.warn(message);
			else if (type === 'info') console.info(message);
			else console.log(message);
			if (data) console.log(data);
			// console.groupEnd();
		}
		resolve();
	});
}

let lastState: HistoryState | null = null;

/**
 * Each time a modal is opened we store the new modal index (stack count).
 * Note this should be called BEFORE creating a new modal.
 * See InternalLinks popstate handler for how this handles closing / re-opening modals.
 * @param type "fetch" or "modal"
 * @param dataset data attrs of the link used to open the modal, passed as modal params
 * @param href URL of content to load into the modal (if type = fetch)
 */
export function doPushState(
	type: string,
	dataset: DOMStringMap,
	href: string | null,
): void {
	lastState = {
		modalIndex: Modal.getLastModalIndex() + 1,
		type,
		href,
		dataset: { ...dataset },
	};
	window.history.pushState(
		lastState,
		'',
		// Don't update the URL for built-in modals like /saved or /media, but do for /search
		type === 'fetch' || href?.indexOf('/search') === 0
			? href
			: window.location.href,
	);
}

export function doReplaceState(href: string | null) {
	lastState = {
		...lastState,
		href,
	};
	window.history.replaceState(lastState, '', href);
}

export function getLastState() {
	return lastState;
}

export function setLastState(state: HistoryState | null) {
	lastState = state;
}
