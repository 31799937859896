import { ACCESSIBILITY } from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import Matter, { Common, Composites } from 'matter-js';

/**
 * Creates a banner element using Matter JS for physics based animation
 */
class Banner {
	el: HTMLElement;
	world: Matter.World;
	width: number;
	height: number;
	engine: Matter.Engine;
	render: Matter.Render;
	composite: typeof Matter.Composite;
	bodies: typeof Matter.Bodies;
	renderer: typeof Matter.Render;
	runner: typeof Matter.Runner;

	constructor(el: HTMLElement) {
		this.el = el;

		// Set data attribute to prevent banner from being initialized more than once
		this.el.dataset.bannerLoaded = 'true';
		this.observeElement();
	}

	// Get the percentage of the width of the banner
	percentX(percent: number) {
		return Math.round((percent / 100) * this.el.offsetWidth);
	}

	// Get the percentage of the height of the banner
	percentY(percent: number) {
		return Math.round((percent / 100) * this.el.offsetHeight);
	}

	// Observe the banner element and create the banner when it is in view
	observeElement() {
		const observer = new IntersectionObserver(
			(entries) => {
				const entry = entries[0];
				if (entry.isIntersecting) {
					this.create();
					// Once the banner is created, stop observing it which will prevent it from being created en masse
					observer.unobserve(this.el);
				}
			},
			{ rootMargin: '0% 0px -100% 0px' },
		);

		observer.observe(this.el);
	}

	// The main function utilising Matter JS to create the banner
	create() {
		const engine = Matter.Engine;
		const bodies = Matter.Bodies;
		this.composite = Matter.Composite;
		this.renderer = Matter.Render;
		this.runner = Matter.Runner;
		this.engine = engine.create();
		const { Mouse } = Matter;
		const { MouseConstraint } = Matter;
		this.render = this.renderer.create({
			element: this.el,
			engine: this.engine,
			options: {
				wireframes: false,
				width: this.percentX(100),
				height: this.percentY(100),
				background: 'transparent',
				pixelRatio: window.devicePixelRatio,
			},
		});
		const bodiesArr: Matter.Body[] = [];
		// The boundaries of the banner
		const ceiling = bodies.rectangle(
			this.percentX(100) / 2,
			this.percentY(0) - 10,
			this.percentX(100) + 10,
			20,
			{ isStatic: true },
		);
		const floor = bodies.rectangle(
			this.percentX(100) / 2,
			this.percentY(100) + 10,
			this.percentX(100),
			20,
			{ isStatic: true },
		);
		const rightWall = bodies.rectangle(
			this.percentX(100) + 10,
			this.percentY(100) / 2,
			20,
			this.percentY(300),
			{ isStatic: true },
		);
		const leftWall = bodies.rectangle(
			this.percentX(0) - 10,
			this.percentY(100) / 2,
			20,
			this.percentY(300),
			{ isStatic: true },
		);

		// We don't want to see the boundaries
		ceiling.render.visible = false;
		floor.render.visible = false;
		rightWall.render.visible = false;
		leftWall.render.visible = false;

		bodiesArr.push(ceiling);
		bodiesArr.push(floor);
		bodiesArr.push(rightWall);
		bodiesArr.push(leftWall);

		this.width = this.el.clientWidth;
		this.height = this.el.clientHeight;

		// Resize the canvas on screen size / orientation change
		window.addEventListener('resize', () => {
			// To actually update the size of walls we'd need to use this on each
			// Matter.Body.setVertices(...)
			// Or, it would probably be easier to world.remove(walls) and re-add using above wrapped in a function
			// But then we'd also need to update the position of the labels to be within bounds (otherwise they can fall out/away)

			// Just scale the world to the new size
			if (this.el.clientWidth > 0 && this.el.clientHeight > 0) {
				Matter.Composite.scale(
					this.world,
					this.el.clientWidth / this.width,
					this.el.clientHeight / this.height,
					{ x: 0, y: 0 },
				);
			}
			this.width = this.el.clientWidth;
			this.height = this.el.clientHeight;
		});

		const bannerLabels = Array.from(
			this.el.querySelectorAll<HTMLElement>('.banner__label'),
		);
		// Calculate a grid we can use to lay out the labels initially
		const cols = Math.max(Math.floor(this.width / 400), 1);
		const rows = Math.ceil(bannerLabels.length / Math.max(cols, 1));
		const maxRowGap = Math.max(this.height / rows - 80, 0);
		const colSpace = this.width / cols;
		const labelRenderers: (() => void)[] = [];

		// Create a generator function for each label that will make the
		// Matter rectangle given a starting x/y coordinate (provided by Composites.stack)
		const labelCreateFns = bannerLabels.map(
			(element: HTMLElement) => (xOffset: number, yOffset: number) => {
				const el = element;
				const rect = element.getBoundingClientRect();
				const bgColour = window.getComputedStyle(el).backgroundColor;
				const txtColour = window.getComputedStyle(el).color;
				let xPos = Math.floor(
					xOffset + Math.random() * Math.max(colSpace - rect.width),
				);
				if (xPos + rect.width > this.width) xPos -= rect.width / 2;
				const box = Matter.Bodies.rectangle(
					Math.max(1, xPos),
					Math.floor(yOffset + Math.random() * maxRowGap),
					rect.width,
					rect.height,
					{
						angle: Math.random() - 0.5,
						render: {
							friction: 0.2,
							fillStyle: bgColour,
							text: {
								content: el.innerText,
								color: txtColour,
								fontFamily: window.getComputedStyle(el).fontFamily,
								size: window.getComputedStyle(el).fontSize,
							},
						},
					},
				);
				// We need to manually render the original label objects on top of the matter physics boxes
				labelRenderers.push(() => {
					const x = box.position?.x || 0;
					const y = box.position?.y || 0;
					el.style.top = `${y - rect.height / 2}px`;
					el.style.left = `${x - rect.width / 2}px`;
					el.style.transform = `rotate(${box.angle}rad)`;
				});
				return box;
			},
		);

		// Creates a grid over the whole banner to allow the labels to start in different places on-screen
		let stackIndex = 0;
		const stack = Composites.stack(
			0, // initial x/y offset
			0,
			cols, // number of cols/rows
			rows,
			0, // col/row gap
			0,
			(x: number, y: number) => {
				stackIndex += 1;
				if (stackIndex <= labelCreateFns.length) {
					return labelCreateFns[stackIndex - 1](x, y);
				}
				return null;
			},
		);

		this.world = this.engine.world;
		this.engine.gravity.y = 0.5;
		this.engine.gravity.x = 0;

		// Wait for the daily affirmation to complete before running
		// This allows labels to be added and positioned behind the scenes
		// and runs the physics simulation once content has loaded
		if (document.querySelector('.daily-affirmation')) {
			EventDispatcher.subscribe('DailyAffirmation', 'complete', () => {
				this.run(bodiesArr, stack);
			});
		} else {
			this.run(bodiesArr, stack);
		}

		// adds mouse control
		const mouse = Mouse.create(this.render.canvas);
		const mouseConstraint = MouseConstraint.create(this.engine, {
			mouse,
			constraint: {
				stiffness: 0.2,
				render: {
					visible: false,
				},
			},
		});

		// Add touch events overrides to the mouse constraint, allowing touch users to interact with the boxes but still swipe the page
		const mouseEl = mouseConstraint.mouse.element;
		mouseEl.removeEventListener('touchstart', mouseConstraint.mouse.mousedown);
		mouseEl.removeEventListener('touchmove', mouseConstraint.mouse.mousemove);
		mouseEl.removeEventListener('touchend', mouseConstraint.mouse.mouseup);
		mouseEl.addEventListener('touchstart', mouseConstraint.mouse.mousedown, {
			passive: true,
		});

		mouseEl.addEventListener('touchmove', (e) => {
			if (mouseConstraint.body) mouseConstraint.mouse.mousemove(e);
		});

		mouseEl.addEventListener('touchend', (e) => {
			if (mouseConstraint.body) mouseConstraint.mouse.mouseup(e);
		});

		this.composite.add(this.world, mouseConstraint);

		// Add gyro control for mobile devices
		const updateGravity = (event: DeviceOrientationEvent) => {
			const orientation = window.screen.orientation.type || 'landscape-primary';
			const { gravity } = this.engine;
			if (typeof event.gamma === 'number' && typeof event.beta === 'number') {
				if (orientation === 'landscape-primary') {
					gravity.x = Common.clamp(event.beta, -90, 90) / 90;
					gravity.y = Common.clamp(-event.gamma, -90, 90) / 90;
				} else if (orientation === 'landscape-secondary') {
					gravity.x = Common.clamp(-event.beta, -90, 90) / 90;
					gravity.y = Common.clamp(event.gamma, -90, 90) / 90;
				} else if (orientation === 'portrait-secondary') {
					gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
					gravity.y = Common.clamp(-event.beta, -90, 90) / 90;
				} else if (orientation === 'portrait-primary') {
					gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
					gravity.y = Common.clamp(event.beta, -90, 90) / 90;
				}
			}
		};

		if (ACCESSIBILITY.reducedMotion) {
			this.engine.gravity.x = 0;
			this.engine.gravity.y = 0;
		} else {
			window.addEventListener('deviceorientation', updateGravity);
		}
		EventDispatcher.subscribe('AccessibilityToggle', 'motion off', () => {
			window.removeEventListener('deviceorientation', updateGravity);
			this.engine.gravity.x = 0;
			this.engine.gravity.y = 0;
		});
		EventDispatcher.subscribe('AccessibilityToggle', 'motion on', () => {
			this.engine.gravity.y = 0.5;
			this.engine.gravity.x = 0;
			window.addEventListener('deviceorientation', updateGravity);
		});

		const update = () => {
			labelRenderers.forEach((renderFn) => renderFn());
			Matter.Engine.update(this.engine);
			requestAnimationFrame(update);
		};

		update();
	}

	run(bodiesArr: Matter.Body[], stack: Matter.Composite) {
		// add all bodies to the this.world
		this.composite.add(this.world, bodiesArr);
		this.composite.add(this.world, stack);
		// run the renderer
		this.renderer.run(this.render);
		// create runner
		const runner = this.runner.create();
		// run the engine
		this.runner.run(runner, this.engine);
	}
}

export default Banner;
