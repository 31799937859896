import gsap from 'gsap';
import SmoothScroll from '@build/components/smooth-scroll/smooth-scroll.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import {
	ACCESSIBILITY,
	BODY,
	MOTION,
	isMD,
} from '@build/util/constants/constants.js';
import BackButtonScrollback from '@build/components/back-button-scrollback/back-button-scrollback.js';
import FetchLoad from '@build/util/fetch-wrapper/fetch-load.js';
import Toast from '@build/components/toast/toast.js';

/**
 * Creates a modal element
 * @param params typically the data attrs of a link/button
 * @param secondaryNav boolean if this should show the secondary nav or not
 * params.modalDirection determines where the modal slides in from, defaults to up.
 * Options are up, down, left, right and full.
 * Full is the same as up, but doesn't have the border radius applied.
 *
 * Note - params.slideMode will automatically set secondaryNav, secondaryNavBlack, and hideHeader
 * and params.videoMode will automatically set slideMode
 *
 * The modal can then have content added through one of:
 * addContent(htmlString) - parsed to HTML and appended
 * addExistingContent(htmlCollection) - appended directly
 * loadUrl(href) - fetches and parses the content and appends whatever is within the <main> element
 */

class Modal {
	public classIdentifier: string;
	public modalWrapper: HTMLElement;
	public smoothscroll: SmoothScroll;
	public hideHeader: boolean;
	public videoMode: boolean;
	public slideMode: boolean;
	public secondaryNav: boolean;
	public secondaryNavBlack: boolean;
	public saveUrl: string;
	private searchUrl: string;
	public backButtonScrollback: BackButtonScrollback;
	public modalContent: HTMLElement;
	private modalScroller: HTMLElement;
	public modalClose: HTMLElement;
	public modalBack: HTMLElement;
	private _height: string;
	private _direction: string;
	private _width: string;
	private params: DOMStringMap;
	// eslint-disable-next-line no-use-before-define
	private static modals: Modal[] = [];
	// This gets replaced with real value once homepage is loaded
	public static HOME_TITLE = 'LoveBetter';
	private parser: DOMParser;
	// opening/closing animation
	public tween: gsap.core.Tween;
	private fetchLoad: FetchLoad;
	private boundOnResize: () => void;
	public pageTitle: string;
	private title: string;
	private loadedFromUrl: boolean;
	private keepHeight: boolean;
	public isClosing: boolean;

	constructor(params: DOMStringMap = {}, secondaryNav: boolean = false) {
		this.classIdentifier = 'Modal';
		this.params = params;
		this._height = this.height();
		this.keepHeight = !!params.modalKeepHeight;
		this._width = this.width();
		this._direction = this.direction();
		this.parser = new DOMParser();
		this.videoMode = Boolean(params.modalVideoMode);
		this.slideMode = Boolean(params.modalSlideMode) || this.videoMode;
		this.secondaryNav =
			secondaryNav === true ||
			Boolean(params.modalSecondaryNav) ||
			this.slideMode;
		this.secondaryNavBlack =
			Boolean(params.modalSecondaryNavBlack) || this.slideMode;
		this.hideHeader = Boolean(params.modalHideHeader) || this.slideMode;
		this.saveUrl = params.modalSaveUrl || '';
		this.searchUrl = params.modalSearchUrl || '';
		// The document title (e.g. browser tab name), if not provided will read from meta for fetched content
		this.pageTitle = params.modalPageTitle || '';
		// An optional title header that will show within the modal (non-full modals)
		this.title = params.modalTitle || '';
		this.boundOnResize = this.onResize.bind(this);
		this.isClosing = false;

		this.createModal();
		Modal.modals.push(this);
	}

	direction() {
		return this.params.modalDirection ? this.params.modalDirection : 'full';
	}

	width() {
		let w = '100';

		if (this.params.modalWidth) {
			w = this.params.modalWidth;
		} else if (this.params.modalDesktopWidth && isMD()) {
			w = this.params.modalDesktopWidth;
		} else if (this.params.modalMobileWidth) {
			w = this.params.modalMobileWidth;
		}

		return this.convertUnit(w, true);
	}

	height() {
		let h = '100';

		if (this.params.modalHeight) {
			h = this.params.modalHeight;
		} else if (this.params.modalDesktopHeight && isMD()) {
			h = this.params.modalDesktopHeight;
		} else if (this.params.modalMobileHeight) {
			h = this.params.modalMobileHeight;
		}

		return this.convertUnit(h);
	}

	convertUnit(value: string, useVw = false) {
		let string;

		if (value.includes('px') || value.includes('%')) {
			string = value;
		} else if (useVw) {
			string = `${value}vw`;
		} else if (CSS.supports('height', '100dvh')) {
			string = `${value}dvh`;
		} else {
			string = `${value}vh`;
		}

		return string;
	}

	/**
	 * Sets the focus on the current modal content
	 * .focus() doesn't work on elements that can't be focused by default, so we get around that by setting the tabIndex to -1 and then focusing it
	 */
	public static setFocus() {
		const lastModal = Modal.getLastModal();
		if (lastModal) {
			if (getComputedStyle(lastModal.modalBack).display !== 'none') {
				lastModal.modalBack.focus();
			} else {
				lastModal.modalClose.focus();
			}
		}
	}

	/**
	 * Creates the modal HTML and adds it to the DOM
	 */
	private createModal(): void {
		const modalTitle = this.title
			? `<h5 class="uppercase md:h6 pt-16 pb-8 md:px-16 px-8 relative z-10">${this.title}</h5>`
			: '';

		const contentClasses = [
			this.slideMode ? 'modal__content--slide-mode logo-colour--dark' : '',
			this.videoMode ? 'modal__content--video-mode logo-colour--dark' : '',
			this.params.modalContentClasses || '',
		].join(' ');

		const modalHtml = `
			<div class="modal" data-direction="${this._direction}">
				<div class="modal__content ${contentClasses}"
					style="height: ${this._height}; width: ${this._width};"
				>
					${modalTitle}
					<button class="modal__back" data-ignore>
						<span class="sr-only">Back</span>
						<span class="c-arrow c-arrow--back"></span>
					</button>
					<button class="modal__close" data-ignore>
						<span class="sr-only">Close</span>
					</button>
					<div class="modal__scroller ${this.params.modalScrollerClasses || ''}"></div>
				</div>
			</div>
		`;

		const doc = this.parser.parseFromString(modalHtml, 'text/html');

		this.modalWrapper = doc.querySelector('.modal') as HTMLElement;
		this.modalContent = doc.querySelector('.modal__content') as HTMLElement;
		this.modalScroller = doc.querySelector('.modal__scroller') as HTMLElement;
		this.modalClose = doc.querySelector('.modal__close') as HTMLElement;
		this.modalBack = doc.querySelector('.modal__back') as HTMLElement;

		BODY.appendChild(this.modalWrapper);

		// Pass the search query down so it's available for the Search component
		this.modalContent.dataset.searchUrl = this.searchUrl;

		[this.modalClose, this.modalBack].forEach((btn) => {
			btn.addEventListener('click', () => this.close());
		});
		// Close the modal when clicking outside of the modal (for non-fullscreen modals)
		this.modalWrapper.addEventListener('click', (e) => {
			if (e.target === this.modalWrapper && Modal.getLastModal() === this) {
				this.close();
			}
		});
	}

	/**
	 * Adds content to the modal
	 * @param data The content to be added to the modal
	 */
	addContent(data: string, loadedFromUrl?: boolean) {
		this.loadedFromUrl = !!loadedFromUrl;
		const parsedContent = this.parser.parseFromString(data, 'text/html');
		const mainElement = parsedContent.querySelector('main');
		if (mainElement && !this.saveUrl) {
			this.saveUrl = mainElement?.dataset.modalSaveUrl || '';
		}
		if (parsedContent.title && !this.pageTitle) {
			this.pageTitle = parsedContent.title || '';
		}
		if (this.pageTitle && document.title !== this.pageTitle) {
			document.title = this.pageTitle;
		}

		// Start faded out
		this.modalScroller.style.opacity = '0';

		// Check if there is a main element, which contains the necessary smoothscroll structure
		// If there isn't, we need to add it manually
		if (!mainElement) {
			this.modalScroller.innerHTML = `
				<div class="smoothscroll h-full overflow-hidden">
					<div class="smoothscroll__content">
						${data}
					</div>
				</div>
				`;
		} else if (this.slideMode) {
			// There's a main element but we don't want to add smoothscroll for slideMode
			this.modalScroller.innerHTML =
				mainElement.querySelector('.smoothscroll__content')?.innerHTML ||
				mainElement.innerHTML;
		} else {
			// There is a main element, so we can just add it to the modal
			this.modalScroller.innerHTML = mainElement.innerHTML;
		}

		this.contentAdded();
	}

	/**
	 * Adds pre-existing content to the modal
	 * @param els The item(s) to be inserted
	 */
	public addExistingContent(els: HTMLCollection, loadedFromUrl?: boolean) {
		let parent: HTMLElement | null = this.modalScroller;
		this.loadedFromUrl = !!loadedFromUrl;

		if (!this.slideMode) {
			this.modalScroller.innerHTML = `
				<div class="smoothscroll h-full overflow-hidden">
						<div class="smoothscroll__content">
					</div>
				</div>
			`;
			parent = this.modalScroller.querySelector('.smoothscroll__content');
		}

		Array.from(els).forEach((el) => {
			parent?.appendChild(el);
		});

		this.contentAdded();
	}

	private contentAdded() {
		const scrollerHeight = this.modalScroller.scrollHeight;
		// prettier-ignore
		const contentHeight = this.modalScroller.querySelector('.smoothscroll__content')?.scrollHeight || scrollerHeight;

		if (
			!this.keepHeight &&
			this._height.indexOf('100') === -1 &&
			contentHeight < scrollerHeight
		) {
			this.modalContent.style.height = `${contentHeight}px`;
		}

		const themeColour = this.modalContent.querySelector(
			':scope [data-modal-theme]',
		) as HTMLElement;

		if (themeColour) {
			this.modalContent.classList.add(themeColour.dataset.modalTheme as string);
		}

		EventDispatcher.dispatch(this.classIdentifier, 'contentAdded');

		// Fade in the content nicely
		gsap.to(this.modalScroller, {
			opacity: 1,
			onComplete: () => {
				// Wait to be rendered before scrolling down to jump link if it has one
				if (this.loadedFromUrl) {
					EventDispatcher.dispatch(this.classIdentifier, 'urlLoaded');
				}
			},
		});
	}

	public loadUrl(href: string) {
		// Initiate our fetcher
		this.fetchLoad = new FetchLoad(this.classIdentifier, href);
		this.fetchLoad.on('afterFetchSuccess', (payload) => {
			// If this modal was closed before this load finished, afterFetchSuccess shouldn't
			// be called anyway because we would have called fetchLoad.destroy to remove listeners
			if (payload?.data) this.addContent(payload.data, true);
			else {
				Toast.show('Failed to load page, please refresh and try again');
				console.error('Did not receive expected modal content', payload);
				this.close();
			}
		});
		this.fetchLoad.on('fetchFailure', (error) => {
			Toast.show('Failed to load page, please refresh and try again');
			console.error('Failed to fetch modal content', error);
			this.close();
		});
		this.fetchLoad.fetchContent();
	}

	/**
	 * Opens the modal
	 */
	public open() {
		if (this.tween) this.tween.kill();

		// Pauses the main instance of SmoothScroll
		SmoothScroll.stopLast(this.hideHeader);

		// Add a background colour to the modal
		gsap.to(this.modalWrapper, {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		});

		const openComplete = () => {
			// Dispatch a global event to let other components know a modal has been opened
			EventDispatcher.dispatch(this.classIdentifier, 'open');
			if (!this.loadedFromUrl) Modal.setFocus();
			requestAnimationFrame(this.setUpSmoothScroll.bind(this));
		};

		const { x, y } = this.getTransformValues();

		if (!ACCESSIBILITY.reducedMotion) {
			// Slide the modal up from the bottom of the screen
			this.tween = gsap.to(this.modalContent, {
				y,
				x,
				onStart: () => {
					EventDispatcher.dispatch(this.classIdentifier, 'opening');
				},
				onComplete: () => {
					openComplete();
				},
			});
		} else {
			EventDispatcher.dispatch(this.classIdentifier, 'opening');

			gsap.set(this.modalContent, {
				x,
				y,
			});
			openComplete();
		}
		window.addEventListener('resize', this.boundOnResize);
	}

	private getTransformValues() {
		// Get the original transform values for the modal (from css values e.g. -50%, 0)
		const modalContentTransform = window
			.getComputedStyle(this.modalContent)
			.getPropertyValue('transform');
		const matrix = modalContentTransform.match(/^matrix\((.+)\)$/);
		const values = matrix ? matrix[1].split(', ') : [];

		const originalX = parseInt(values[4], 10) || 0;
		const originalY = parseInt(values[5], 10) || 0;

		// Set the starting positions for the modal so we can animate them out accurately
		this.modalContent.dataset.x = `${originalX}`;
		this.modalContent.dataset.y = `${originalY}`;

		let y = `${originalY}px`;
		let x = `${originalX}px`;

		// we need gsap to take over the transform values to prevent conflicts with css
		gsap.set(this.modalContent, {
			xPercent: 0,
			x,
			yPercent: 0,
			y,
		});

		const direction = this.modalWrapper.dataset.direction || 'full';
		// Update x or y end values based on the direction we want to go
		switch (direction) {
			case 'down':
				y = '100%';
				break;

			case 'left':
				x = '100%';
				break;

			case 'right':
				x = '-100%';
				break;

			case 'full':
			case 'up':
			default:
				y = '-100%';
				break;
		}

		return { x, y };
	}

	private onResize() {
		this._height = this.height();
		this._width = this.width();
		this.modalContent.style.width = this._width;
		this.modalContent.style.height = this._height;

		const scrollerHeight = this.modalScroller.scrollHeight;
		// prettier-ignore
		const contentHeight = this.modalScroller.querySelector('.smoothscroll__content')?.scrollHeight || scrollerHeight;

		if (this._height.indexOf('100') === -1 && contentHeight < scrollerHeight) {
			this.modalContent.style.height = `${contentHeight}px`;
		}

		this.modalContent.style.removeProperty('transform');
		const { x, y } = this.getTransformValues();
		gsap.set(this.modalContent, {
			x,
			y,
		});
	}

	private setUpSmoothScroll() {
		const smoothScrollElement =
			this.modalWrapper.querySelector('.smoothscroll');
		if (smoothScrollElement && !this.smoothscroll) {
			// Create a new instance of SmoothScroll within the modal
			this.smoothscroll = new SmoothScroll(
				smoothScrollElement as HTMLElement,
				this.hideHeader,
			);

			// Start the new instance of SmoothScroll
			this.backButtonScrollback = new BackButtonScrollback(
				this.modalBack,
				this.smoothscroll.lenis,
			);
		}
	}

	/**
	 * Closes the modal (actually pops the history state/URL back a step) which should close the latest modal
	 */
	public close() {
		// Stop duplicate clicks on back or modal wrapper from closing more modals than just the current one
		if (!this.isClosing) {
			// Note that internal links may set this false again if it determines we're still on the same page (jump links)
			this.isClosing = true;
			window.history.back();
		}
	}

	/**
	 * Note - do not call this directly, it should only be called by the popstate listener in internal-links
	 */
	public actuallyClose() {
		if (this.fetchLoad) this.fetchLoad.destroy();
		if (this.tween) this.tween.kill();
		window.removeEventListener('resize', this.boundOnResize);
		// Remove this from the modal list
		Modal.modals = Modal.modals.filter((modal) => modal !== this);

		const prevModal = Modal.getLastModal();
		if (prevModal?.pageTitle) {
			if (document.title !== prevModal.pageTitle)
				document.title = prevModal.pageTitle;
		} else if (document.title !== Modal.HOME_TITLE) {
			document.title = Modal.HOME_TITLE;
		}

		// Get the original x and y values for the modal so we animate out the right distance
		const originalX = this.modalContent.dataset.x
			? this.modalContent.dataset.x
			: 0;
		const originalY = this.modalContent.dataset.y
			? this.modalContent.dataset.y
			: 0;

		let y = `${originalY}px`;
		let x = `${originalX}px`;

		// Update x or y end values based on the direction we want to go
		const direction = this.modalWrapper.dataset.direction || 'full';
		switch (direction) {
			case 'down':
				y = '-100%';
				break;

			case 'left':
				x = '-100%';
				break;

			case 'right':
				x = '100%';
				break;

			case 'full':
			case 'up':
			default:
				y = '100%';
				break;
		}

		// Fade out the modal background colour
		this.tween = gsap.to(this.modalWrapper, {
			backgroundColor: 'transparent',
			ease: MOTION.easing.gsap.in,
		});

		const closeComplete = () => {
			if (this.backButtonScrollback) {
				this.backButtonScrollback.destroy();
			}
			// Remove instance of Smoothscroll from array and destroy Lenis
			if (this.smoothscroll?.id)
				SmoothScroll.destroyInstance(this.smoothscroll.id);

			// Remove the current modal from the DOM
			this.modalWrapper.remove();

			// Restart scroll on prev modal/home
			SmoothScroll.restartLast(Modal.getLastModal()?.hideHeader);

			Modal.setFocus();
		};

		if (!ACCESSIBILITY.reducedMotion) {
			// Slide the modal back down to the bottom of the screen
			gsap.to(this.modalContent, {
				y,
				x,
				ease: MOTION.easing.gsap.in,
				onStart: () => {
					EventDispatcher.dispatch(this.classIdentifier, 'closing');
				},
				onComplete: () => {
					closeComplete();
					// Dispatch a global event to let other components know a modal has been closed
					EventDispatcher.dispatch(this.classIdentifier, 'close');
				},
			});
		} else {
			EventDispatcher.dispatch(this.classIdentifier, 'closing');
			closeComplete();
			EventDispatcher.dispatch(this.classIdentifier, 'close');
		}
	}

	/**
	 * Sets up the global events for modals e.g. closing on escape
	 */
	static init(): void {
		this.closeLastModalOnEscape();

		EventDispatcher.subscribe('Modal', 'contentAdded', () => {
			// Initiate smooth scroll on the latest modal content (if it's not already)
			const latestModal = Modal.getLastModal();
			latestModal?.setUpSmoothScroll();

			// Fix scroll for keyboard navigation for links in the sticky header
			document
				.querySelectorAll('.modal__header a:not([data-has-focus-listener])')
				.forEach((link) => {
					link.addEventListener('focus', () => {
						const modal = Modal.getLastModal();
						modal?.smoothscroll?.lenis?.scrollTo(0);
					});
					link.setAttribute('data-has-focus-listener', '');
				});
		});
	}

	/**
	 * Adds a keydown event that listens for the escape key and triggers a modal close
	 */
	static closeLastModalOnEscape(): void {
		document.addEventListener('keydown', async (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				this.closeLastModal();
			}
		});
	}

	/**
	 * Closes the last opened modal
	 */
	static closeLastModal() {
		const modal = Modal.getLastModal();
		if (modal) modal.close();
	}

	/**
	 * Note - this should only be called by the InternalLinks popstate handler!
	 * @param index closes all modals from this index onwards (though calls actuallyClose in reverse order)
	 */
	static closeModals(index: number) {
		Modal.modals
			.slice(index)
			.reverse()
			.forEach((modal) => {
				modal.actuallyClose();
			});
	}

	/**
	 * Returns the last modal that was created, which is the one that is currently open
	 */
	static getLastModal(): Modal | undefined {
		return Modal.modals[Modal.modals.length - 1];
	}

	static getLastModalIndex(): number {
		return Modal.modals.length - 1;
	}

	static getModal(index: number): Modal | undefined {
		return Modal.modals[index];
	}
}

export default Modal;
