import Banner from '@build/components/banner/banner.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

/**
 * Initialises any banner components on DailyAffirmation.complete and Modal.contentAdded
 */
class BannerInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initBanners();
		});

		this.initBanners();
	}

	private initBanners() {
		// The data attribute is used to prevent the banner from being initialized more than once
		const banners = document.querySelectorAll(
			'.banner:not([data-banner-loaded])',
		);
		banners.forEach((banner) => new Banner(banner as HTMLElement));
	}
}

export default BannerInit;
