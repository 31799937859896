import { ACCESSIBILITY, MAIN } from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import gsap from 'gsap';
import Modal from '@build/components/modal/modal.js';

class DailyAffirmation {
	public classIdentifier: string;
	public element: HTMLElement | null;
	public bar: HTMLElement | null;
	public content: HTMLElement | null;
	public block: HTMLElement | null;
	public state: HTMLElement | null;

	constructor() {
		this.classIdentifier = 'DailyAffirmation';
		this.element = document.querySelector('.daily-affirmation');

		// If the element doesn't exist, kill the script
		if (!this.element) return;

		this.content = this.element.querySelector('.daily-affirmation__content');
		this.block = this.element.querySelector('.daily-affirmation__block');
		this.bar = this.element.querySelector('.daily-affirmation__bar');
		this.state = this.element.querySelector('.daily-affirmation__state');

		// First delay, adds a class which increases the time taken to animate the bar
		const to1 = setTimeout(() => {
			this.bar!.classList.add('daily-affirmation__bar--loading-still');
		}, 5000);

		// Second delay, adds a class which greatly increases the time taken to animate the bar
		const to2 = setTimeout(() => {
			// Update the state text for screen readers
			this.state!.innerText = 'Still loading';
			this.bar!.classList.add('daily-affirmation__bar--loading-never-ending');
		}, 15000);

		// All done! Finally.
		window.addEventListener('load', () => {
			// Update the state text for screen readers
			this.state!.innerText = 'Loading Complete';

			// Clear the timeouts so they don't run after the bar has finished loading
			clearTimeout(to1);
			clearTimeout(to2);

			// Class the finishes the loading animation
			this.bar!.classList.add('daily-affirmation__bar--loading-complete');

			// The time right now
			const timeNow = Math.floor(Date.now() / 1000);
			const timeDiff = timeNow - window.AFFIRMATION_INIT;
			// The delay to remove the element
			// If the time between now and initialisation is less than 3 seconds, delay for 3 seconds less the difference
			// Otherwise, delay for 0.5 seconds
			const delay = timeDiff < 3 ? (3 - timeDiff) * 1000 : 500;

			setTimeout(() => {
				this.remove();
			}, delay);
		});
	}

	private remove() {
		const delay = ACCESSIBILITY.reducedMotion ? 0 : 0.3;
		const y = window.innerHeight;

		// Start with the main content slightly offset
		gsap.set(MAIN, {
			y: y / 4,
		});

		// Slide out the daily affirmation content
		gsap.to(this.content, {
			y: -y,
		});

		// Slide out the daily affirmation block of colour
		gsap.to(this.block, {
			y: -y,
			delay,
			onComplete: () => {
				// We can safely remove the Daily Affirmation now
				this.element!.remove();
				// Trigger an event to let other components know the Daily Affirmation has been removed
				EventDispatcher.dispatch(this.classIdentifier, 'complete');
				// Move focus to the latest modal if exists
				Modal.setFocus();
			},
		});

		// Slide the main content back to its original position
		gsap.to(MAIN, {
			y: 0,
			delay,
		});
	}
}

export default DailyAffirmation;
