import ThemeChanger from '@build/components/theme-changer/theme-changer.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

class AffirmationsList {
	private items: HTMLElement[];

	constructor(private element: HTMLElement) {
		// Ensure we don't re-initialise
		this.element.dataset.init = 'true';
		this.items = Array.from(
			this.element.querySelectorAll('[data-affirmation-url]'),
		) as HTMLElement[];

		// Update download URL on theme change
		EventDispatcher.subscribe('ThemeChanger', 'themeChange', () => {
			this.init();
		});

		this.init();
	}

	private init() {
		const themeSlug = ThemeChanger.getTheme();
		// We kinda need this
		if (!themeSlug) return;
		this.items.forEach((el) => {
			const urlTemplate = el.dataset.affirmationUrl;
			const url = urlTemplate?.replace('{{THEME_SLUG}}', themeSlug);
			const link = el.querySelector('a[download]') as HTMLAnchorElement;
			if (!url || !link) return;
			link.href = url;
			el.classList.remove('hidden');
		});
	}
}

export default AffirmationsList;
