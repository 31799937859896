import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Saved from '@build/components/saved/saved.js';

/**
 * Renders the list of saved items when it's opened
 */
class SavedInit {
	private listings: Saved[];

	constructor() {
		this.listings = [];
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initSavedListing();
		});
		// Remove listeners etc when the saved modal is closed
		EventDispatcher.subscribe('Modal', 'close', () => {
			this.listings.filter((savedListing) => !savedListing.destroyIfHanging());
		});
	}

	private initSavedListing() {
		const listings = Array.from(
			document.querySelectorAll('.saved-listing:not([data-init])'),
		) as HTMLElement[];
		listings
			.filter((listing) => !listing.closest('.saved-listing--ignore'))
			.forEach((listing) =>
				this.listings.push(new Saved(listing as HTMLElement)),
			);
	}
}

export default SavedInit;
