class LocalStorageUtil {
	static getItem(key: string): string | null {
		return localStorage.getItem(key);
	}

	static setItem(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	static removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	static setObject(key: string, value: object): void {
		const jsonString = JSON.stringify(value);
		this.setItem(key, jsonString);
	}

	static getObject(key: string): object | null {
		const jsonString = this.getItem(key);
		if (jsonString) {
			return JSON.parse(jsonString);
		}
		return null;
	}
}

export default LocalStorageUtil;
