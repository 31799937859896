import { ACCESSIBILITY } from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class Reel {
	static SLIDE_SPEED = 300;
	classIdentifier: string;
	swiper: Swiper;

	constructor(private element: HTMLElement) {
		this.classIdentifier = this.constructor.name;
		this.element.dataset.init = 'true';
		const swiperParent = this.element.querySelector(
			'.swiper-parent',
		) as HTMLElement;
		if (!swiperParent) return;

		// Hook up swipe up/down actions
		new Swiper(swiperParent, {
			modules: [Navigation],
			direction: 'vertical',
			speed: ACCESSIBILITY.reducedMotion ? 0 : Reel.SLIDE_SPEED,
			loop: true,
			navigation: {
				nextEl: this.element.querySelector('.c-slider__next') as HTMLElement,
				prevEl: this.element.querySelector('.c-slider__prev') as HTMLElement,
			},
			on: {
				// We need to wait until it's initialised to set the focus, otherwise it conflicts with the modal setFocus
				afterInit: (e) => {
					this.swiper = e;
					this.toggleFocus(true);
					this.swiper.on('slideChange', () => this.toggleFocus());
				},
			},
		});

		// Toggle the speed based on motion settings
		EventDispatcher.subscribe('AccessibilityToggle', 'motion off', () => {
			this.swiper.params.speed = 0;
		});

		EventDispatcher.subscribe('AccessibilityToggle', 'motion on', () => {
			this.swiper.params.speed = Reel.SLIDE_SPEED;
		});
	}

	private toggleFocus(switchFocus = false) {
		// The new slide to focus on
		const slide = this.element.querySelector(
			`.swiper-slide[data-index="${this.swiper.realIndex + 1}"]`,
		);

		if (!slide) return;

		// Unfocus all slides
		this.element
			.querySelectorAll('.swiper-slide')
			.forEach((el) => el.setAttribute('tabindex', '-1'));

		const currentVideo = slide.querySelector('video');

		// Pause all video elements and make them unfocusable
		this.element.querySelectorAll('video').forEach((vid) => {
			if (vid !== currentVideo) {
				vid.pause();
				vid.setAttribute('tabindex', '-1');
			}
		});

		// Make all buttons and links unfocusable
		this.element
			.querySelectorAll('button:not(.c-slider__btn), a')
			.forEach((btn) => btn.setAttribute('tabindex', '-1'));

		// Make the new slide focusable between the buttons (1 and 3 are the buttons, 2 is the current slide)
		slide.removeAttribute('tabindex');

		// Start playing the video on this current slide (if there is one)
		if (currentVideo) {
			if (currentVideo.paused) currentVideo.play();
			currentVideo.setAttribute('tabindex', '2');
		}

		// Make buttons and links within the slide focusable
		const currentBtns = slide.querySelectorAll('button, a');
		currentBtns.forEach((btn) => btn.setAttribute('tabindex', '2'));

		// Switch focus to the next button when opening the reel modal
		if (switchFocus) {
			(this.element.querySelector('.c-slider__next') as HTMLElement).focus();
		}
	}
}

export default Reel;
