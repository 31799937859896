import gsap from 'gsap';

/**
 * A component that shows a small message temporarily
 */
class Toast {
	public static el: HTMLElement;
	public static textEl: HTMLElement;
	public static closeEl: HTMLElement;
	public static tween: gsap.core.Tween | gsap.core.Timeline;

	constructor() {
		Toast.el = document.querySelector('.c-toast') as HTMLElement;
		Toast.textEl = Toast.el?.querySelector('.c-toast__text') as HTMLElement;
		Toast.closeEl = Toast.el?.querySelector('.c-toast__close') as HTMLElement;

		if (!Toast.el || !Toast.textEl || !Toast.closeEl) return;

		// Allow user to close the message faster
		Toast.closeEl.addEventListener('click', () => Toast.hide());
	}

	/**
	 * Shows a small message temporarily just above the bottom nav
	 * @param message plain text string, ideally not too long
	 */
	static show(message: string) {
		if (!message || !Toast.textEl) return;
		// Set the message
		Toast.textEl.innerText = message;
		if (Toast.tween && Toast.tween.isActive()) Toast.tween.kill();

		// Fade in the toast
		Toast.tween = gsap.timeline();
		Toast.tween.to(Toast.el, {
			autoAlpha: 1,
			// It seems we need to explicity change this for it to have effect
			onComplete: () => Toast.toggleClass(),
		});
		// Fade out the toast after a delay
		Toast.tween.to(Toast.el, {
			autoAlpha: 0,
			delay: 3,
			onComplete: () => Toast.toggleClass(),
		});
	}

	/**
	 * Hides the toast message (if exists)
	 */
	static hide() {
		if (!Toast.el) return;
		if (Toast.tween && Toast.tween.isActive()) Toast.tween.kill();
		Toast.tween = gsap.to(Toast.el, {
			autoAlpha: 0,
			onComplete: () => Toast.toggleClass(),
		});
	}

	private static toggleClass() {
		Toast.el.querySelector('.c-toast__item')?.classList.toggle('glass');
	}
}

export default Toast;
