import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

/**
 * Updates the padding based on size of Article aside
 */
class ArticleSpacing {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.adjustSpacing();
		});

		// adjust on resize
		let to: number | undefined;
		window.addEventListener('resize', () => {
			// Add a delay for performance
			clearTimeout(to);
			to = setTimeout(this.adjustSpacing, 150);
		});
	}

	private adjustSpacing() {
		const asides = Array.from(
			document.querySelectorAll('.article .elemental--aside'),
		) as HTMLElement[];
		asides.forEach((aside) => {
			const parent = aside.parentElement;

			// Get all elements with white bg at top of article (before a group-break)
			const whiteEls: HTMLElement[] = [];
			const children = Array.from(parent?.children || []) as HTMLElement[];
			children.some((el) => {
				if (el.classList.contains('elemental--group-break')) {
					return true;
				}
				if (
					!el.classList.contains('elemental--aside') &&
					!el.classList.contains('elemental__hero')
				) {
					whiteEls.push(el);
				}
				return false;
			});

			// We shouldn't be in this state - should either have white Els or have no --aside
			if (!whiteEls.length) return;

			// Work out the height difference and add additional height to the last white bg el if needed
			const styleMap = window.getComputedStyle(aside);
			const extraSpace = parseInt(styleMap.top, 10) || 0; // additional offset for the "top" value
			const minHeight = aside.getBoundingClientRect().height + extraSpace;
			const currentHeight = whiteEls
				.map(
					(el) =>
						el.querySelector('.elemental__element')?.getBoundingClientRect()
							.height || 0,
				)
				.reduce((t: any, h: any) => t + h, 0);

			const diff =
				styleMap.position === 'absolute'
					? Math.max(0, minHeight - currentHeight)
					: 0;
			whiteEls[whiteEls.length - 1].style.paddingBottom =
				`${Math.ceil(diff)}px`;
		});
	}
}

export default ArticleSpacing;
