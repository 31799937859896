import gsap from 'gsap';
import { ACCESSIBILITY } from '@build/util/constants/constants.js';

class IconExplosion {
	static isAnimating: boolean;
	private triggers: any;
	private icon: any;

	constructor(private element: HTMLElement) {
		// Check if the element exists
		if (!this.element) return;
		// The icon to animate
		this.icon = this.element.querySelector('.icon-explosion');
		// Check if the icon exists
		if (!this.icon) return;
		// Set the initial state so we don't reinitialize the component
		this.element.dataset.explosionInit = 'true';
		// Bind the click event
		this.element.addEventListener('click', () => this.explode());
	}

	private explode() {
		// Check if the animation is already running to prevent a mass DOM injection
		if (IconExplosion.isAnimating || ACCESSIBILITY.reducedMotion) return;

		IconExplosion.isAnimating = true;

		// Create a fragment to hold all the clones
		const fragment = document.createDocumentFragment();
		// Randomize the number of icons to create (between 50 and 100)
		const numberOfIcons = Math.floor(Math.random() * (100 - 50) + 50);
		// Randomize the spread of the icons based on window size
		const spreadLength =
			window.innerWidth > window.innerHeight
				? window.innerWidth
				: window.innerHeight;
		const spread = Math.floor(
			Math.random() * (spreadLength - spreadLength / 2) + spreadLength / 2,
		);

		// Create the clones
		for (let i = 0; i < numberOfIcons; i++) {
			const clone = this.icon.cloneNode(true) as HTMLElement;
			clone.classList.add('icon-explosion--clone');
			clone.style.setProperty(
				'--random-factor',
				Math.floor(Math.random() * 4 + 1).toString(),
			);

			const x = Math.random() * spread - spread / 2;
			const y = Math.random() * spread - spread / 2;

			clone.dataset.x = x.toString();
			clone.dataset.y = y.toString();

			gsap.set(clone, {
				opacity: 1,
			});

			fragment.appendChild(clone);
		}

		// Append the fragment to the DOM
		this.element.appendChild(fragment);

		// The closest scroll wrapper
		const wrapper = this.element.closest('.smoothscroll') as HTMLElement | null;
		// Disable scrolling on the wrapper so the icons don't force an annoying scrollbar
		if (wrapper) wrapper.style.overflow = 'hidden';

		// Animate the clones
		gsap.to(this.element.querySelectorAll('.icon-explosion--clone'), {
			x: (index, element) => element.dataset.x,
			y: (index, element) => element.dataset.y,
			opacity: 0,
			duration: 1,
			ease: 'power2.out',
			onComplete: () => {
				// Remove all the clones
				this.element
					.querySelectorAll('.icon-explosion--clone')
					.forEach((el) => el.remove());

				// Set the animation state to false
				IconExplosion.isAnimating = false;

				// Reset scrolling on the wrapper
				if (wrapper) wrapper.removeAttribute('style');
			},
		});
	}
}

export default IconExplosion;
