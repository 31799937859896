import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import LocalStorageUtil from '@build/util/local-storage/local-storage.js';
import { BODY, LOCAL_STORAGE } from '@build/util/constants/constants.js';
import Modal from '@build/components/modal/modal.js';

class ThemeChanger {
	public modal: HTMLElement;
	public themeItems: NodeListOf<Element>;
	public element: any;
	public static classIdentifier = 'ThemeChanger';

	constructor() {
		// Elements already exist on the page, and will be duplicated into the Modal when it opens
		this.themeItems = document.querySelectorAll('.theme-changer__item');

		// Subscribe to the Modal open event
		EventDispatcher.subscribe('Modal', 'open', () => {
			// Get the last modal that was opened
			this.modal = Modal.getLastModal()?.modalWrapper;
			if (!this.modal) return;
			// Initialise the theme changer
			this.init();
		});

		// Load theme from local storage
		ThemeChanger.applyPersistedTheme();
	}

	init() {
		this.element = this.modal.querySelector('.theme-changer');

		if (!this.element) return;

		// Add event listeners to the theme changer modal
		this.modal.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			// Check if the target is a theme changer button or if we should use the parent instead
			const button = target.classList.contains('theme-changer__item')
				? target
				: target.parentElement;

			// Only proceed if the target is a theme changer button or
			// If the button is already active, do nothing
			if (
				!button?.classList.contains('theme-changer__item') ||
				button?.classList.contains('theme-changer--active')
			) {
				return;
			}

			// Get the slug from the buttons data attribute
			const { slug } = button.dataset;

			// Deactivate the current theme and activate the new one
			ThemeChanger.toggleTheme(slug!);
		});
	}

	/**
	 * Deactivate the current theme by removing the theme classes from the body and the active class from the theme changer button
	 * Activate the new theme by adding the theme classes to the body and the active class to the theme changer button
	 * @param theme The slug of the theme to activate
	 */
	public static toggleTheme(theme: string) {
		if (BODY.classList.contains(`theme__${theme}`)) {
			return;
		}
		this.removeThemeClasses();
		// Deactivate the current theme circle
		const activeTheme = document.querySelectorAll('.theme-changer--active');
		activeTheme.forEach((el) => el.classList.remove('theme-changer--active'));
		// Activate the new theme circle
		const newTheme = document.querySelectorAll(
			`.theme-changer__item[data-slug="${theme}"]`,
		);
		newTheme.forEach((el) => el.classList.add('theme-changer--active'));
		// Add the theme class to the body
		BODY.classList.add(`theme__${theme}`);
		// Save the theme to local storage
		ThemeChanger.persistTheme(theme);
		EventDispatcher.dispatch(this.classIdentifier, 'themeChange');
	}

	/**
	 * Remove all theme classes from the body
	 */
	private static removeThemeClasses() {
		const bodyClasses = BODY.classList;

		// Get all classes on the BODY element that start with 'theme__'
		const themeClasses = Array.from(bodyClasses).filter((className) =>
			className.startsWith('theme__'),
		);

		// Remove each theme class
		themeClasses.forEach((className) => bodyClasses.remove(className));
	}

	/**
	 * Save the theme to local storage
	 */
	private static persistTheme(theme: string) {
		LocalStorageUtil.setItem(LOCAL_STORAGE.theme, theme);
	}

	/**
	 * Get the handle of the current theme from local storage (if set) otherwise default
	 */
	public static getTheme() {
		const theme = LocalStorageUtil.getItem(LOCAL_STORAGE.theme);
		if (!theme) {
			const defaultThemeEl = document.querySelector('[data-default-theme]');
			return defaultThemeEl?.getAttribute('data-slug');
		}
		return theme;
	}

	/**
	 * Apply the theme from local storage
	 */
	private static applyPersistedTheme() {
		const theme = ThemeChanger.getTheme();
		if (theme) this.toggleTheme(theme);
	}
}

export default ThemeChanger;
