import gsap from 'gsap';

// Accessibility settings that get set based on the user's browser settings
// See AccessibilitySettings class
export const ACCESSIBILITY = {
	reducedMotion: true,
	highContrast: true,
};

// Animation settings
// If the user has requested reduced motion, we can disable animations by setting the duration to 0 for all animations and have a linear ease.
// Empty object to be filled with values in updateMotionValues()
export const MOTION = {
	duration: {
		fast: 0,
		med: 0,
		slow: 0,
	},
	easing: {
		gsap: {
			out: '',
			in: '',
			inOut: '',
		},
		css: {
			out: '',
			in: '',
			inOut: '',
		},
	},
};

// Set the motion values based on the user's accessibility settings
export const updateMotionValues = () => {
	MOTION.duration.fast = ACCESSIBILITY.reducedMotion ? 0 : 0.2;
	MOTION.duration.med = ACCESSIBILITY.reducedMotion ? 0 : 0.5;
	MOTION.duration.slow = ACCESSIBILITY.reducedMotion ? 0 : 1;
	MOTION.easing.gsap.out = ACCESSIBILITY.reducedMotion
		? 'linear'
		: 'power4.out';
	MOTION.easing.gsap.in = ACCESSIBILITY.reducedMotion ? 'linear' : 'power4.in';
	MOTION.easing.gsap.inOut = ACCESSIBILITY.reducedMotion
		? 'linear'
		: 'power4.inOut';
	MOTION.easing.css.out = 'cubic-bezier(0.36,0.66,0.04,1)';
	MOTION.easing.css.in = 'cubic-bezier(0.34,0.04,0.6,1)';
	MOTION.easing.css.inOut = 'cubic-bezier(0.76,0,0.24,1)';

	// set GSAP defaults meaning we don't have to specify easing and duration every time
	gsap.defaults({
		ease: MOTION.easing.gsap.out,
		duration: MOTION.duration.med,
	});
};

// Names of local storage items
export const LOCAL_STORAGE = {
	polls: 'completed-polls',
	theme: 'chosen-theme',
	motion: 'reduced-motion',
	contrast: 'high-contrast',
	saved: 'saved-items',
};

// Array of custom fetch events
export const FETCH_EVENTS = [
	'preFetch',
	'duringFetch',
	'afterFetchSuccess',
	'afterContentLoad',
	'fetchFailure',
];

// Some DOM elements we will use throughout the site
export const HTML = document.documentElement;
export const BODY = document.body;
export const SCROLLER = document.querySelector('.smoothscroll') as HTMLElement;
export const MAIN = document.querySelector('main') as HTMLElement;
export const LOGO = document.querySelector('.header__logo') as HTMLElement;
export const HEADER = document.querySelector('#header') as HTMLElement;
export const NAV = document.querySelector('.nav-main') as HTMLElement;
export const PRIMARY_NAV = NAV.querySelectorAll(
	'.nav-main__link--primary',
) as NodeListOf<HTMLElement>;
export const SECONDARY_NAV = NAV.querySelectorAll(
	'.nav-main__link--secondary',
) as NodeListOf<HTMLElement>;
export const FOOTER = document.querySelector('footer') as HTMLElement;

// matches() is faster to call each time than matchMedia().matches
export const matches = (mediaQuery: string) => matchMedia(mediaQuery).matches;
// NB: use these device detection functions with caution as not all devices recognise the media queries. Test thoroughly.
// tests for touch specific media queries
export const isTouchEnabled = () =>
	matches('(pointer:coarse)') && !matches('(hover)');
// tests for stylus specific media queries
export const isStylusEnabled = () =>
	matches('(pointer:fine)') && !matches('(hover)');
// tests for mouse specific media queries
export const isMouseEnabled = () =>
	matches('(pointer:fine)') && matches('(hover)');

// breakpoint media queries
export const isXS = () => matches('(min-width: 320px)');
export const isSM = () => matches('(min-width: 640px)');
export const isMD = () => matches('(min-width: 768px)');
export const isLG = () => matches('(min-width: 1024px)');
export const isXL = () => matches('(min-width: 1280px)');
export const is2XL = () => matches('(min-width: 1536px)');

export type SearchBits = {
	keywords: string;
	tags: string[];
	media: string;
};

export type HistoryState = {
	modalIndex?: number;
	type?: string;
	href?: string | null;
	dataset?: any;
};
