import gsap from 'gsap';

class Quiz {
	private element: HTMLElement;
	private questions: NodeListOf<Element>;
	private responses: NodeListOf<Element>;
	private questionsWrapper: any;
	private currentQuestionIndex: number;
	private completeBtn: any;
	private nextBtn: any;
	private prevBtn: any;
	private countEl: any;
	private isAnimating = false;
	private currentQuestion: Element;
	private inputs: NodeListOf<Element>;

	constructor(quiz: HTMLElement) {
		// The key elements: the quiz, the questions, and the responses
		this.element = quiz;
		this.questions = this.element?.querySelectorAll('.c-quiz__question');
		this.responses = this.element?.querySelectorAll('.c-quiz__response');

		// If any of the key elements are missing, return
		if (!this.element || !this.questions || !this.responses.length) return;

		this.element.dataset.init = 'true';

		this.questionsWrapper = this.element.querySelector('.c-quiz__questions');
		this.inputs = this.element.querySelectorAll('input[type="radio"]');

		// The first question index
		this.currentQuestionIndex = 0;
		// The first question
		this.currentQuestion = this.questions[this.currentQuestionIndex];

		// The action buttons
		this.completeBtn = this.element.querySelector(
			'.c-quiz__controls--complete',
		);
		this.nextBtn = this.element.querySelector('.c-quiz__controls--next');
		this.prevBtn = this.element.querySelector('.c-quiz__controls--prev');

		// THe question counter
		this.countEl = this.element.querySelector('.c-quiz__count--current');

		// Kick things off by apply event listeners to inputs and buttons
		this.initListeners();
	}

	private initListeners() {
		this.inputs.forEach((input) =>
			input.addEventListener('change', () => this.updateControls()),
		);
		this.nextBtn.addEventListener('click', (e: Event) => {
			e.preventDefault();
			this.toggleQuestion('next');
		});
		this.prevBtn.addEventListener('click', (e: Event) => {
			e.preventDefault();
			this.toggleQuestion('prev');
		});
		this.completeBtn.addEventListener('click', (e: Event) => {
			e.preventDefault();
			this.completed();
		});
	}

	private updateControls() {
		// Check if the current question is the last
		const isEnd = this.currentQuestionIndex === this.questions.length - 1;

		// If the current question is the first, disable the previous button
		if (this.currentQuestionIndex === 0) this.prevBtn.disabled = true;
		// Otherwise, enable it if it's disabled
		else if (this.prevBtn.disabled) this.prevBtn.disabled = false;

		// Enable the next button when a radio input is selected and the next button is disabled
		if (
			!this.currentQuestion.querySelector('input:checked') &&
			!this.nextBtn.disabled
		) {
			this.nextBtn.disabled = true;
		}
		// otherwise, enable it if it's disabled
		else if (this.nextBtn.disabled) this.nextBtn.disabled = false;

		// Enable the complete button when a radio input is selected and the complete button is disabled
		if (
			isEnd &&
			!this.currentQuestion.querySelector('input:checked') &&
			!this.completeBtn.disabled
		) {
			this.completeBtn.disabled = true;
		}
		// otherwise, enable it if it's disabled
		else if (this.completeBtn.disabled) this.completeBtn.disabled = false;

		// Update current question number
		this.countEl.innerText = this.currentQuestionIndex + 1;

		// Toggle the next and complete buttons depending on if the current question is the last
		if (isEnd) {
			this.nextBtn.style.display = 'none';
			this.completeBtn.classList.remove('!hidden');
		} else if (!this.completeBtn.classList.contains('!hidden')) {
			this.nextBtn.style.display = 'block';
			this.completeBtn.classList.add('!hidden');
		}
	}

	private toggleQuestion(direction: 'next' | 'prev') {
		if (this.isAnimating) return;

		this.isAnimating = true;

		const currentQuestion = this.questions[this.currentQuestionIndex];
		const nextQuestion =
			direction === 'prev'
				? this.questions[this.currentQuestionIndex - 1]
				: this.questions[this.currentQuestionIndex + 1];
		const newIndex =
			direction === 'prev'
				? this.currentQuestionIndex - 1
				: this.currentQuestionIndex + 1;

		this.animateCard(currentQuestion, nextQuestion, direction);

		// Update the current question index
		this.currentQuestionIndex = newIndex;
		// Update the current question
		this.currentQuestion = nextQuestion;

		// Trigger the update controls function which will enable/disable buttons
		this.updateControls();
	}

	private completed() {
		// Get all checked inputs
		const checkedInputs = Array.from(this.inputs).filter(
			(input: Element) => (input as HTMLInputElement).checked,
		);

		// Check if any of the checked inputs have a value of 'Red'
		const hasRedValue = Array.from(checkedInputs).some(
			(input: Element) => (input as HTMLInputElement).value === 'Red',
		);

		// Check if any of the checked inputs have a value of 'Orange'
		const hasOrangeValue = Array.from(checkedInputs).some(
			(input: Element) => (input as HTMLInputElement).value === 'Orange',
		);

		// Get the default response with a data attribute of 'Green'
		let chosenResponse = Array.from(this.responses).find(
			(response: Element) =>
				(response as HTMLElement).dataset.weight === 'Green',
		);

		// If the checked inputs have a value of 'Red', show the response with a data attribute of 'Red'
		if (hasRedValue) {
			chosenResponse = Array.from(this.responses).find(
				(response: Element) =>
					(response as HTMLElement).dataset.weight === 'Red',
			);
		}
		// If the checked inputs have a value of 'Orange', show the response with a data attribute of 'Orange'
		else if (hasOrangeValue) {
			chosenResponse = Array.from(this.responses).find(
				(response: Element) =>
					(response as HTMLElement).dataset.weight === 'Orange',
			);
		}

		if (!chosenResponse) return;

		const currentQuestion = this.questions[this.currentQuestionIndex];

		// Make it visible to SR users
		chosenResponse.removeAttribute('aria-hidden');

		const tl = this.animateCard(currentQuestion, chosenResponse, 'next');

		// Fade out the controls
		tl.to(
			this.element.querySelector('.c-quiz__controls'),
			{
				autoAlpha: 0,
				display: 'none',
			},
			'<',
		);
	}

	animateCard(current: any, next: any, direction: 'next' | 'prev') {
		const tl = gsap.timeline({
			onComplete: () => {
				this.isAnimating = false;
			},
		});

		// Set the height to prevent it collapsing as we hide/show content
		this.questionsWrapper.style.height = `${current.clientHeight}px`;

		// Show the next question but keep it hidden so we can calculate the height
		gsap.set(next, {
			x:
				direction === 'prev'
					? this.questionsWrapper.offsetWidth * -1
					: this.questionsWrapper.offsetWidth,
			rotation: direction === 'prev' ? -10 : 10,
			autoAlpha: 0,
			display: 'block',
			position: 'absolute',
		});

		// Slide out the current question
		tl.to(current, {
			x:
				direction === 'prev'
					? this.questionsWrapper.offsetWidth
					: this.questionsWrapper.offsetWidth * -1,
			rotation: direction === 'prev' ? 10 : -10,
			opacity: 0,
			clearProps: 'all',
			onComplete: () => {
				// Remove active class
				current.classList.remove('c-quiz__question--active');
			},
		})
			// Slide in the next question
			.to(next, {
				x: 0,
				rotation: 0,
				autoAlpha: 1,
				display: 'block',
				clearProps: 'all',
				onComplete: () => {
					// Add active class
					next.classList.add('c-quiz__question--active');
				},
			})
			// At the same time as the next question, animate the height of the wrapper
			.to(
				this.questionsWrapper,
				{
					height: next.scrollHeight,
					clearProps: 'height',
				},
				'<',
			);

		return tl;
	}
}

export default Quiz;
