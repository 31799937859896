import {
	ACCESSIBILITY,
	LOCAL_STORAGE,
} from '@build/util/constants/constants.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import FetchLoad from '@build/util/fetch-wrapper/fetch-load.js';
import LocalStorageUtil from '@build/util/local-storage/local-storage.js';
import gsap from 'gsap';

type SavedItem = {
	type: string;
	id: string;
	timestamp: number;
};

class Saved {
	private static classIdentifier = 'Saved';
	private fetchLoad: FetchLoad;
	private listingParent: HTMLElement;
	private onUpdateEventHandler: () => void;

	constructor(private element: HTMLElement) {
		this.element.dataset.init = 'true';
		// Where the items will be fetched and added to
		this.listingParent = this.element.querySelector(
			'.saved-listing__list',
		) as HTMLElement;
		if (!this.listingParent) return;

		// Initiate our fetcher
		this.fetchLoad = new FetchLoad(Saved.classIdentifier, '');
		this.fetchLoad.on('afterFetchSuccess', this.afterFetchSuccess.bind(this));
		this.fetchLoad.on('fetchFailure', (error) => {
			console.error('Failed to fetch saved items', error);
			this.showFailMessage();
		});
		// Load and display the saved items
		this.updateItems();

		// Subscribe to when they save/un-save things and update items
		// Track the exact function instance to remove the listener on close
		this.onUpdateEventHandler = this.updateItems.bind(this);
		EventDispatcher.subscribe(
			Saved.classIdentifier,
			'savedItemsChanged',
			this.onUpdateEventHandler,
		);
	}

	/**
	 * Updates the display, loading details from the server if they've items saved
	 */
	private updateItems(): void {
		const items = Saved.getSavedItems();
		if (!items.length) {
			this.listingParent.innerHTML = '';
			this.toggleEmptyMessage(true);
			return;
		}
		// Set aria loading state
		this.listingParent.parentElement?.setAttribute('aria-live', 'polite');
		this.listingParent.parentElement?.setAttribute('aria-busy', 'true');
		this.listingParent.setAttribute('aria-label', 'Loading');
		// Fetch items
		const params = this.generateRequestUrlParams(items);
		this.fetchLoad.url = `/saved/?${params}`;
		this.fetchLoad.fetchContent();
	}

	/**
	 * Updates the display to the CMS-configured message for when there's not yet any saved items
	 * @param show true to show, false to hide
	 */
	private toggleEmptyMessage(show: boolean): void {
		const emptyEl = this.element.querySelector('.saved-listing__empty');
		if (!emptyEl) return;
		gsap.set(emptyEl, { opacity: show ? 0 : 1 });
		emptyEl.classList.toggle('hidden', !show);
		gsap.to(emptyEl, {
			opacity: show ? 1 : 0,
			delay: 0.2,
		});
	}

	/**
	 * Updates the display to the load failed message
	 */
	private showFailMessage(): void {
		this.toggleEmptyMessage(false);
		this.listingParent.innerHTML = this.failMessage();
		this.clearAriaLoading();
	}

	/**
	 * Shows the items, if fetch succeeded
	 * @param payload .data should be the string of the whole page rendered by Listing.ss
	 */
	private afterFetchSuccess(payload: any): void {
		if (!payload?.data) {
			this.showFailMessage();
			return;
		}
		const parser = new DOMParser();
		const parsedContent = parser.parseFromString(payload.data, 'text/html');
		// Only replace the content within the scroll container so we don't have to recreate the root SmoothScroll
		const content = parsedContent.querySelector(
			'.saved-listing__list',
		) as HTMLElement;
		// Hide the empty message
		this.toggleEmptyMessage(false);
		// Helper to allow us to sort the items
		const timestampMap = new Map<string, number>();
		Saved.getSavedItems().forEach((item) => {
			timestampMap.set(`${item.type}-${item.id}`, item.timestamp);
		});
		// Hide whatever is currently showing (outdated list)
		gsap.to(this.listingParent, {
			opacity: 0,
			onComplete: () => {
				this.listingParent.innerHTML = '';
				// Reorder the items based on timestamp saved
				(Array.from(content.children) as HTMLElement[])
					.sort((a, b) => {
						const aKey = `${a.dataset.type}-${a.dataset.id}`;
						const bKey = `${b.dataset.type}-${b.dataset.id}`;
						return (
							(timestampMap.get(bKey) || 0) - (timestampMap.get(aKey) || 0)
						);
					})
					.forEach((el) => {
						// Hide each item so we can fade them in
						el.classList.add('opacity-0');
						// Add them to the page
						this.listingParent.appendChild(el);
					});
				// Show the parent element again (it'll look empty anyway)
				this.listingParent.style.opacity = '1';
				// Fade the items in
				gsap.to(this.listingParent.children, {
					opacity: 1,
					stagger: ACCESSIBILITY.reducedMotion ? 0 : 0.05,
					resolve: () => {
						this.clearAriaLoading();
					},
				});
				// Let other components register e.g. internal-links
				EventDispatcher.dispatch('global', 'contentAdded');
			},
		});
	}

	private clearAriaLoading() {
		this.listingParent.parentElement?.removeAttribute('aria-live');
		this.listingParent.parentElement?.removeAttribute('aria-busy');
		this.listingParent.removeAttribute('aria-label');
	}

	/**
	 * Text to display when loading has failed
	 * @returns string of HTML content
	 */
	private failMessage(): string {
		return `
			<div class="space-y-8 pt-40 px-8 text-center">
				<h3>Failed to load saved items</h3>
				<p>Please refresh and try again</p>
			</div>
		`;
	}

	/**
	 * Creates the request URL params for loading data from server e.g. article=12,43&reel=19
	 * @param items SavedItem[] from getSavedItems
	 * @returns string
	 */
	private generateRequestUrlParams(items: SavedItem[]): string {
		const typeMap = items.reduce((map, item) => {
			if (!map.get(item.type)) map.set(item.type, [item.id]);
			else map.get(item.type)?.push(item.id);
			return map;
		}, new Map<SavedItem['type'], [SavedItem['id']]>());
		return Array.from(typeMap)
			.map((tuple) => `${tuple[0]}=${tuple[1].join()}`)
			.join('&');
	}

	/**
	 * Removes any listeners if this has been removed from the page (modal closed)
	 * @returns boolean whether this has been removed
	 */
	public destroyIfHanging() {
		const hanging = !document.contains(this.element);
		if (hanging) {
			this.fetchLoad.destroy();
			EventDispatcher.off(
				Saved.classIdentifier,
				'savedItemsChanged',
				this.onUpdateEventHandler,
			);
		}
		return hanging;
	}

	/**
	 * Gets the list of saved items
	 * @returns SavedItem[]
	 */
	private static getSavedItems(): SavedItem[] {
		return LocalStorageUtil.getObject(LOCAL_STORAGE.saved) || [];
	}

	/**
	 * Generate a SaveItem given a URL that must match /save/[type]/[id]
	 * where type is article|reel|sticker|affirmation e.g. /save/article/14
	 * @param url string
	 * @returns SavedItem or false if invalid URL
	 */
	private static createItemFromUrl(url: string | null): SavedItem | false {
		const pieces =
			typeof url === 'string' && url.match(/^\/save\/(\w+)\/(\d+)/);
		return (
			!pieces || {
				type: pieces[1],
				id: pieces[2],
				timestamp: new Date().getTime(),
			}
		);
	}

	/**
	 * Determines if the given URL matches an already saved item
	 * @param url string see Saved.createItemFromUrl
	 * @returns boolean
	 */
	public static isSaved(url: string) {
		const newItem = Saved.createItemFromUrl(url);
		if (!newItem) return false;
		return !!Saved.getSavedItems().find(
			(item: SavedItem) => item.type === newItem.type && item.id === newItem.id,
		);
	}

	/**
	 * Toggle the save state for the item matching the given URL
	 * @param url string see Saved.createItemFromUrl
	 * @returns boolean true if added, false if removed (or invalid)
	 */
	public static toggleSave(url: string) {
		const newItem = Saved.createItemFromUrl(url);
		// Invalid URL, what can we do
		if (!newItem) return false;
		let removed = false;
		// Find the matching item in the saved list and remove it
		const items = Saved.getSavedItems().filter((item: SavedItem) => {
			if (item.type === newItem.type && item.id === newItem.id) {
				removed = true;
				return false;
			}
			return true;
		});
		// If it wasn't found then we're adding it
		if (!removed) {
			items.push(newItem);
		}
		// Write back the removal/addition
		LocalStorageUtil.setObject(LOCAL_STORAGE.saved, items);
		// Update the heart links (on all modals) for this particular item
		document.querySelectorAll(`a[href="${url}"`).forEach((el) => {
			el.classList.toggle('saved--active', !removed);
		});
		// Let other things happen, like update the saved for later modal(s) if open
		EventDispatcher.dispatch(Saved.classIdentifier, 'savedItemsChanged');
		return !removed;
	}
}
export default Saved;
