import Carousel from '@build/components/carousel/carousel.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

/**
 * Initialises any carousel components now and on contentAdded
 */
class CarouselInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initCarousels();
		});

		window.addEventListener('load', () => {
			this.initCarousels();
		});
	}

	private initCarousels() {
		const carousels = document.querySelectorAll(
			'[data-carousel-type]:not([data-init])',
		);
		carousels.forEach((carousel) => new Carousel(carousel as HTMLElement));
	}
}

export default CarouselInit;
