import Poll from '@build/components/poll/poll.js';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';

/**
 * Initialises any poll components now and on contentAdded
 */
class PollInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.initPolls();
		});

		this.initPolls();
	}

	private initPolls() {
		const polls = document.querySelectorAll('.c-poll:not([data-init])');
		polls.forEach((poll) => {
			if (!poll.closest('[data-ignore]')) {
				new Poll(poll as HTMLElement);
			}
		});
	}
}

export default PollInit;
