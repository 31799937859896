import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import {
	ACCESSIBILITY,
	BODY,
	MOTION,
} from '@build/util/constants/constants.js';
import Modal from '@build/components/modal/modal.js';
import gsap from 'gsap';
import SmoothScroll from '@build/components/smooth-scroll/smooth-scroll.js';

class FooterAnimation {
	private element: HTMLElement | null;
	private timeout: number;
	private labels: NodeListOf<HTMLParagraphElement> | undefined;
	private boundaryWidth: number;
	private boundaryHeight: number;
	private compass: string[];
	private paused = true;
	private labelData: Map<
		string,
		{
			label: HTMLElement;
			labelParams: any;
			clones: HTMLElement[];
			direction: string;
			rafId: number | null;
		}
	>;
	private speed = 2;
	private cloneLimit = 20;
	private checkPlayState: () => void;

	constructor() {
		this.element = document.querySelector('.footer-screensaver');
		this.labels = this.element?.querySelectorAll('p');

		if (!this.element || !this.labels) return;

		// the boundary of the bouncing labels
		this.boundaryWidth = this.element?.clientWidth || window.innerWidth;
		this.boundaryHeight = this.element?.clientHeight || window.innerHeight;
		// the directions the labels can move
		this.compass = ['ne', 'nw', 'se', 'sw'];
		// the data for each label
		this.labelData = new Map();

		// Update the boundary on resize
		let timeout: number | undefined;
		window.addEventListener('resize', () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				this.boundaryWidth = this.element?.clientWidth || window.innerWidth;
				this.boundaryHeight = this.element?.clientHeight || window.innerHeight;
			}, 100);
		});

		// Start the animation when the window is loaded
		// mainly to ensure the font has loaded and the labels width is accurate
		window.addEventListener('load', () => {
			this.labels?.forEach((label, index) => this.setupLabel(label, index));
		});

		// Move the footer into the latest modal
		EventDispatcher.subscribe('Modal', 'contentAdded', () => {
			if (this.timeout) window.clearTimeout(this.timeout);

			const modal = Modal.getLastModal();
			const modalEl = modal?.modalContent;
			const footer = modalEl?.querySelector('footer');

			if (
				this.element &&
				modalEl &&
				footer &&
				!modalEl.contains(this.element)
			) {
				// Delay adding footer until modal content has faded in
				this.timeout = window.setTimeout(() => {
					if (this.element) {
						modalEl.append(this.element);
						this.observer(modal);
					}
				}, MOTION.duration.med * 1000);
			}
		});

		// Move the footer into the previous modal or home page
		EventDispatcher.subscribe('Modal', 'closing', () => {
			if (this.timeout) window.clearTimeout(this.timeout);

			if (!this.element) return;

			const modal = Modal.getLastModal();
			const modalEl = modal?.modalContent;
			const footer = modalEl?.querySelector('footer');

			if (modalEl && footer) {
				if (!modalEl.contains(this.element)) {
					// Delay adding footer until modal content has faded in
					this.timeout = window.setTimeout(() => {
						if (this.element) {
							modalEl.append(this.element);
							this.observer(modal);
						}
					}, MOTION.duration.med * 1000);
				}
			} else if (this.element.parentElement !== BODY) {
				BODY.append(this.element);
				this.observer();
			}
		});

		// Pause/play animation when reduced motion is enabled/disabled
		EventDispatcher.subscribe(
			'AccessibilityToggle',
			['motion off', 'motion on'],
			() => {
				// Call the latest copy of this fn to check reduced motion + current scroll
				if (typeof this.checkPlayState === 'function') {
					this.checkPlayState();
				}
			},
		);

		// Start the animation when the footer comes into view
		this.observer();
	}

	/*
	 * Observer for the footer to start the animation when it comes into view
	 */
	observer(modal?: Modal) {
		const scroller = modal
			? modal.smoothscroll
			: SmoothScroll.getLastSmoothScroll();

		this.checkPlayState = () => {
			// Don't play the animation if reduced motion is enabled
			if (ACCESSIBILITY.reducedMotion) {
				if (!this.paused) {
					this.paused = true;
					this.cancelRaf();
				}
				return;
			}

			const contentHeight =
				(scroller.content?.scrollHeight || 0) - window.innerHeight;
			const scrollTop = scroller.lenis?.targetScroll;

			// Play the footer when it comes into view
			if (scrollTop >= contentHeight && this.paused) {
				this.paused = false;
				this.labels?.forEach((label, index) => this.raf(index.toString()));
			}
			// Stop the footer when it goes out of view
			else if (scrollTop < contentHeight && !this.paused) {
				this.paused = true;
				this.cancelRaf();
			}
		};

		scroller.lenis?.on('scroll', this.checkPlayState.bind(this));
	}

	/*
	 * Setup the label data and create clones
	 */
	setupLabel(label: HTMLElement, index: number) {
		this.labelData.set(index.toString(), {
			label,
			labelParams: {
				width: label.offsetWidth,
				height: label.offsetHeight,
				xMin: 0,
				yMin: 0,
				xMax: 0,
				yMax: 0,
				translateX: Math.floor(Math.random() * this.boundaryWidth + 1),
				translateY: Math.floor(Math.random() * this.boundaryHeight + 1),
			},
			clones: [],
			direction: this.compass[Math.floor(Math.random() * this.compass.length)],
			rafId: null,
		});

		const labelData = this.labelData.get(index.toString());
		const { labelParams, clones } = labelData || {};

		labelParams.xMax = this.boundaryWidth - labelParams.width;
		labelParams.xMax = this.boundaryWidth - labelParams.width;
		labelParams.yMax = this.boundaryHeight - labelParams.height;

		// Update the boundary on resize
		let timeout: number | undefined;
		window.addEventListener('resize', () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				labelParams.xMax = this.boundaryWidth - label.offsetWidth;
				labelParams.yMax = this.boundaryHeight - label.offsetHeight;
			}, 100);
		});

		// Create clones of the label to create a tail effect
		for (let i = 0; i < this.cloneLimit; i++) {
			const element: HTMLElement = label.cloneNode(true) as HTMLElement;
			element.style.zIndex = `${i * -1}`;
			clones[i] = element;
			this.element?.appendChild(element);
		}

		// Set the initial position of the label and clones
		gsap.set(label, {
			x: labelParams.translateX,
			y: labelParams.translateY,
		});

		clones?.forEach((clone) => {
			gsap.set(clone, {
				x: labelParams.translateX,
				y: labelParams.translateY,
			});
		});
	}

	/*
	 * RequestAnimationFrame for the label animation
	 */
	raf(index: string) {
		// get the label data for the index
		const labelData = this.labelData.get(index);

		// if the label data doesn't exist, return
		if (!labelData) return;

		const { label, labelParams, clones } = labelData;
		let { direction } = labelData;

		/*
		 * Set the limits for the label to bounce off of and change direction if it hits a limit
		 */
		const setLimits = () => {
			if (labelParams.translateY <= labelParams.yMin) {
				if (direction === 'nw') {
					direction = 'sw';
				} else if (direction === 'ne') {
					direction = 'se';
				}
			}
			if (labelParams.translateY >= labelParams.yMax) {
				if (direction === 'se') {
					direction = 'ne';
				} else if (direction === 'sw') {
					direction = 'nw';
				}
			}
			if (labelParams.translateX <= labelParams.xMin) {
				if (direction === 'nw') {
					direction = 'ne';
				} else if (direction === 'sw') {
					direction = 'se';
				}
			}
			if (labelParams.translateX >= labelParams.xMax) {
				if (direction === 'ne') {
					direction = 'nw';
				} else if (direction === 'se') {
					direction = 'sw';
				}
			}
		};

		/*
		 * Set the position of the label based on the direction and speed
		 */
		const setPosition = () => {
			if (direction === 'ne') {
				labelParams.translateX += this.speed;
				labelParams.translateY -= this.speed;
			} else if (direction === 'nw') {
				labelParams.translateX -= this.speed;
				labelParams.translateY -= this.speed;
			} else if (direction === 'se') {
				labelParams.translateX += this.speed;
				labelParams.translateY += this.speed;
			} else if (direction === 'sw') {
				labelParams.translateX -= this.speed;
				labelParams.translateY += this.speed;
			}

			// update the limits after the position has changed
			setLimits();
		};

		/*
		 * Move the label and clones to the new position
		 */
		// We want the offset to be a very small number, this magic number does the trick
		const delayOffset = window.innerWidth / 10000;
		const move = () => {
			setPosition();

			gsap.set(label, {
				x: labelParams.translateX,
				y: labelParams.translateY,
			});

			clones.forEach((clone, i) => {
				gsap.set(clone, {
					x: labelParams.translateX,
					y: labelParams.translateY,
					// the delay creates the tail effect
					delay: i * delayOffset,
				});
			});
		};

		const rafCallback = () => {
			labelData.rafId = requestAnimationFrame(rafCallback);
			move();
		};

		labelData.rafId = requestAnimationFrame(rafCallback);
	}

	/*
	 * Cancel the RequestAnimationFrame for each label animation
	 */
	cancelRaf() {
		this.labelData.forEach((labelData) => {
			const data = labelData;
			if (data.rafId) {
				cancelAnimationFrame(data.rafId);
				data.rafId = null;
			}
		});
	}
}

export default FooterAnimation;
