import gsap from 'gsap';
import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import { Draggable, InertiaPlugin } from 'gsap/all';
import { ACCESSIBILITY } from '@build/util/constants/constants.js';
import Modal from '@build/components/modal/modal.js';

gsap.registerPlugin(Draggable, InertiaPlugin);

// Adjust this number to change the time each slide is shown for (in seconds)
const SLIDE_TIME_SECONDS = 5;

class InShort {
	classIdentifier: string;
	slides: Element[];
	index: number;
	prevButton: Element | null;
	nextButton: Element | null;
	slideCount: number;
	autoPlayTween: gsap.core.Tween;
	pressStart: number;
	wrapper: HTMLElement;
	sliding: boolean;

	constructor(
		private element: HTMLElement,
		private autoplay: boolean,
	) {
		this.classIdentifier = 'InShort';

		this.slides = Array.from(this.element.querySelectorAll('.c-slider__slide'));

		if (!this.slides.length) return;

		this.prevButton = this.element.querySelector('.c-slider__prev');
		this.nextButton = this.element.querySelector('.c-slider__next');
		this.index = 0;
		this.slideCount = this.slides.length;
		this.autoplay = ACCESSIBILITY.reducedMotion ? false : this.autoplay;

		// Add a data attribute to the element to show it has been initialized
		// This is used to prevent the script from running multiple times on the same element
		this.element.dataset.init = 'true';

		this.prevButton?.addEventListener('click', () => {
			this.showSlide(this.index - 1);
		});

		this.nextButton?.addEventListener('click', () => {
			this.showSlide(this.index + 1);
		});

		// The slides wrapping element
		this.wrapper = this.element.querySelector(
			'.c-slider__slides',
		) as HTMLElement;
		this.initSwiping();

		EventDispatcher.subscribe('Modal', 'close', () => {
			if (!document.contains(this.element)) this.destroy();
		});

		this.showSlide(this.index);
	}

	private initSwiping() {
		// Don't do anything if there is no wrapper
		if (!this.wrapper) return;

		const slidesWidth = this.slides.reduce((w, s) => w + s.clientWidth, 0);
		const slideWidth = slidesWidth / this.slideCount;
		const snapValues = this.slides.map((el, i) => -i * slideWidth);

		Draggable.create(this.wrapper, {
			type: 'x',
			inertia: true,
			bounds: {
				minX: -slidesWidth + slideWidth,
				maxX: 0,
			},
			throwResistance: 6000,
			maxDuration: 2,
			onDragStart: () => {
				if (this.autoPlayTween) this.autoPlayTween.pause();
			},
			onDragEnd: () => {
				if (this.autoPlayTween) this.autoPlayTween.play();
			},
			snap: (endVal) => {
				// track which we've snapped to so we can update other state that we're on that slide (index/progress)
				const toSnapTo = gsap.utils.snap(snapValues, endVal);
				snapValues.some((val, i) => {
					if (val === toSnapTo && this.index !== i) {
						this.showSlide(i);
						return true;
					}
					return false;
				});
				return toSnapTo;
			},
			// Allow pausing autoplay when holding finger down
			onPress: () => {
				this.pressStart = new Date().getTime();
				if (this.autoPlayTween) this.autoPlayTween.pause();
			},
			// Handle click left or right side to go back/forward
			onClick: (ev: PointerEvent) => {
				// Skip if still moving (fixes iOS bug of click/mouse event sometimes firing twice)
				if (this.sliding) return;
				const target = ev.target as HTMLElement;

				// If they were just holding their finger down (for longer than 300ms), unpause autoplay and don't move on
				if (new Date().getTime() > this.pressStart + 300) {
					if (this.autoPlayTween) this.autoPlayTween.play();
					return;
				}

				// Determine if they clicked on the left 1/3rd (go back) or right 2/3rds (go forward)
				const rect = target.getBoundingClientRect();
				const clickWidth = rect.width / 3;
				if (ev.x > rect.left && ev.x < rect.left + clickWidth) {
					this.showSlide(this.index - 1);
				} else if (ev.x < rect.right && ev.x > rect.right - 2 * clickWidth) {
					this.showSlide(this.index + 1);
				}
			},
		});
	}

	private showSlide(index: number) {
		// Don't do anything if there is no wrapper
		if (!this.wrapper) return;

		// Stop the previous progress tracker
		if (this.autoPlayTween) this.autoPlayTween.kill();

		// Close the modal if we've gone past the end
		if (index >= this.slideCount) {
			Modal.getLastModal()?.close();
			return;
		}

		// Ensure we're not going back/forward further than possible
		this.index = Math.min(this.slideCount - 1, Math.max(0, index));
		const slide = this.slides[this.index];

		// In case something was too long and the user had scrolled down
		Modal.getLastModal()?.smoothscroll?.lenis?.scrollTo(0);

		// Disable buttons if at start or end
		this.prevButton?.toggleAttribute('disabled', this.index === 0);
		this.nextButton?.toggleAttribute(
			'disabled',
			this.index === this.slideCount - 1,
		);
		// kill any draggable animation that may still be happening (only tends to overlap if they clicked while it was still swiping)
		Draggable.get(this.wrapper)?.tween?.pause();
		// Animate to the new slide
		this.sliding = true;
		gsap.to(this.wrapper, {
			x: -slide.clientWidth * this.index,
			onComplete: () => {
				this.sliding = false;
			},
			onInterrupt: () => {
				this.sliding = false;
			},
		});

		// All the progress indicators
		const progressItems = this.element.querySelectorAll<HTMLElement>(
			'.c-slider__progress-inner',
		);
		// The progress indicator for this slide
		const progressItem = this.element.querySelector<HTMLElement>(
			`.c-slider__progress-inner[data-index="${this.index + 1}"]`,
		);
		// Update the progress indicator to this slide
		progressItems.forEach((el) => {
			if (el.dataset?.index < slide?.dataset?.index) {
				el.style.setProperty('width', '100%');
			} else {
				el.style.setProperty('width', '0%');
			}
		});

		// Hide any links in hidden slides from tab index
		this.wrapper
			.querySelectorAll('a')
			.forEach((link) => link.setAttribute('tabindex', '-1'));

		// Re-show links in this slide
		slide
			.querySelectorAll('a')
			.forEach((link) => link.removeAttribute('tabindex'));

		// Start filling in the progress indicator (if autoplay enabled)
		if (progressItem) {
			if (this.autoplay) {
				this.startTimer(progressItem);
			} else {
				progressItem.style.width = '100%';
			}
		}
	}

	/**
	 * Animate the progress indicator and move to the next slide on completion
	 */
	private startTimer(progressItem: HTMLElement) {
		this.autoPlayTween = gsap.to(progressItem, {
			width: '100%',
			duration: SLIDE_TIME_SECONDS,
			ease: 'none',
			onComplete: () => {
				this.showSlide(this.index + 1);
			},
		});
	}

	// Do any tidy up necessary to stop this component being active
	private destroy() {
		if (this.autoPlayTween) this.autoPlayTween.kill();
	}
}

export default InShort;
