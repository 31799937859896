import EventDispatcher from '@build/util/fetch-wrapper/event-dispatcher.js';
import IconExplosion from './icon-explosion.js';

class IconExplosionInit {
	constructor() {
		EventDispatcher.subscribe('global', 'contentAdded', () => {
			this.init();
		});

		this.init();
	}

	private init() {
		const els = document.querySelectorAll(
			'.icon-explosion--trigger:not([data-explosion-init])',
		);

		els.forEach((el) => {
			if (el.closest('.internal-links--ignore') === null) {
				new IconExplosion(el as HTMLElement);
			}
		});
	}
}

export default IconExplosionInit;
